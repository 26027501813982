import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';

// ---------------------------------------------------------

import {
  input_container,
  input_field,
  input_icon,
  input_label,
  error,
  has_error
} from '../styles.module.scss';

// ---------------------------------------------------------

const InputField = (props) => {
  const {
    icon,
    placeholder,
    label,
    searchValueCallback,
    inputType,
    inputName,
    pattern,
    customError,
    required,
    defaultInputValue,
    defaultOnChange
  } = props;

  const typeMap = {
    tel: {
      customError: 'Please enter a valid phone number. Ex: 123-123-1234',
      pattern: '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'
    }
  };

  const inputRef = useRef(null);
  const errorMsg =
    inputRef?.current?.validationMessage &&
    (customError || typeMap[inputType]?.customError || inputRef?.current?.validationMessage);
  const [inputValue, setInputValue] = useState(defaultInputValue || '');
  const [showError, setShowError] = useState(false);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setInputValue(searchTerm);
    if (searchValueCallback) searchValueCallback(searchTerm);
    if (defaultOnChange) defaultOnChange(searchTerm);
  };

  // ---------------------------------------------------------

  return (
    <div className={input_container}>
      <label className={input_label}>
        {label && <span>{label}</span>}
        <input
          ref={inputRef}
          type={inputType}
          className={classNames(input_field, { [has_error]: errorMsg && showError })}
          placeholder={placeholder !== undefined ? placeholder : typeMap[inputType]?.placeholder}
          pattern={pattern || typeMap[inputType]?.pattern}
          onChange={handleInputChange}
          onFocus={() => setShowError(false)}
          onBlur={() => setShowError(true)}
          value={inputValue}
          name={inputName}
          required={required}
        />
        {errorMsg && showError && <div className={error}>{errorMsg}</div>}
      </label>
      {icon && (
        <div className={input_icon}>
          <Icon type={icon?.iconType} name={icon?.iconName} />
        </div>
      )}
    </div>
  );
};

// ---------------------------------------------------------

InputField.propTypes = {
  customError: PropTypes.string,

  /**
   * Specifies the default value
   */
  defaultInputValue: PropTypes.string,
  /**
   * Specifies the onChange function that sets the default value
   */
  defaultOnChange: PropTypes.func,

  icon: PropTypes.object,

  inputName: PropTypes.string,

  inputType: PropTypes.oneOf(['email', 'tel', 'text', 'date', 'time', 'number', 'search']),

  label: PropTypes.string,

  pattern: PropTypes.string,

  placeholder: PropTypes.string,

  required: PropTypes.bool,

  searchValueCallback: PropTypes.func
};

InputField.defaultProps = {
  inputType: 'text',
  required: false
};

export default InputField;
