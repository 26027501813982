import propertyTypeMap from '@src/data/property-type-map.json';

export default {
  props: {
    clearIcon: null,
    clearLabel: null,
    groupName: 'Example',
    items: [
      {
        label: 'Option 1',
        value: 1
      },
      {
        label: 'Option 2',
        value: 2
      },
      {
        label: 'Option 3',
        value: 3
      }
    ]
  },
  testing: {
    additionalOptions: true,
    items: [...propertyTypeMap.slice(1)]
  },
  withAdditionalOptions: {
    additionalOptions: true,
    items: [
      {
        checkboxes: [
          {
            label: 'Single Family'
          },
          {
            label: 'Condo/Townhouses'
          },
          { label: 'Farms' }
        ],
        isChecked: true,
        label: 'Residential'
      },
      {
        checkboxes: [
          {
            isChecked: true,
            label: '2-4 Family Properties'
          },
          {
            label: '5+ Properties/Apartments'
          }
        ],
        label: 'Multi-Families'
      },
      {
        label: 'Commercial'
      },
      {
        label: 'Land/lots'
      }
    ]
  },
  withIconAndHeading: {
    heading: 'Select state',
    items: [
      {
        icon: 'ohio',
        label: 'Ohio',
        value: 'ohio'
      },
      {
        icon: 'kentucky',
        label: 'Kentucky',
        value: 'kentucky'
      },
      {
        icon: 'indiana',
        label: 'Indiana',
        value: 'indiana'
      }
    ],
    theme: 'with-icon'
  },
  withLabelInside: {
    clearLabel: 'All',
    items: [
      {
        label: '1+'
      },
      {
        label: '2+'
      },
      {
        label: '3+'
      },
      {
        label: '4+'
      },
      {
        label: '5+'
      }
    ],
    theme: 'with-label-inside'
  }
};
