import { Store } from 'react-notifications-component';

const Toast = {
  notify: (opts) => {
    Store.addNotification({
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      container: 'bottom-right',
      dismiss: { duration: 5000, waitForAnimation: true },
      ...opts
    });
  }
};

export default Toast;
