// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Link = (props) => <Component {...transform(props)} />;

// ---------------------------------------------------------

export default Link;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
