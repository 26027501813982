const parsePixelsToInt = (value) => {
  if (!value) return undefined;
  if (!isNaN(value)) return parseInt(value);

  const pixelIndex = value.lastIndexOf('px');
  const valWithoutPixels = pixelIndex === -1 ? value : value.substring(0, pixelIndex);
  if (!isNaN(valWithoutPixels)) return parseInt(value);
};

export default (input) => {
  return {
    ...input,
    image: {
      ...input?.image,
      height: parsePixelsToInt(input?.image?.height),
      src: input?.image?.src || input?.image?.url,
      width: parsePixelsToInt(input?.image?.width)
    }
  };
};
