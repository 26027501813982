// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Jumbotron = (props) => <Component {...transform(props)} />;

export default Jumbotron;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
