const propsItems = [
  {
    label: 'House'
  },
  {
    label: 'Apartment'
  },
  {
    label: 'Townhouse'
  }
];

// ---------------------------------------------------------

export default {
  layoutIsHalf: {
    layout: 'half'
  },
  layoutIsThird: {
    layout: 'third'
  },
  props: {
    checkedCallback: () => {},
    items: propsItems
  },
  withIcons: {
    hasIcons: true,
    items: [
      {
        icon: 'new-listing',
        label: 'New Listing'
      },
      {
        icon: 'new-construction',
        label: 'New Construction'
      },
      {
        icon: 'coming-soon',
        label: 'Coming Soon'
      }
    ],
    selectAllLabel: 'All Propeties',
    selectallIcon: 'open-house'
  },
  withoutAnySelectionOption: {
    selectAllLabel: null
  }
};
