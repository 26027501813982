/* eslint-disable no-unused-vars */
import React from 'react';
import Router from 'next/router';
import FiveHundred from '@src/pages/500';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.resetError = this.resetError.bind(this);
  }

  resetError() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', this.resetError);
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.resetError);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.Rollbar) {
      window.Rollbar.error(error);
    } else {
      console.error('Rollbar (inactive) Error:', error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <FiveHundred />;
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

export default ErrorBoundary;
