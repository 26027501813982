export default (input) => {
  return {
    ...input,
    items: input?.items?.map((el) => {
      return {
        icon: el.icon,
        isChecked: el.selectedByDefault || el.isChecked || false,
        key: el.key,
        label: el.subType || el.label,
        value: el.subTypeIds?.toString() || el.value?.toString()
      };
    })
  };
};
