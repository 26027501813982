import { ApolloProvider } from '@apollo/client';
import { UserProvider } from '@auth0/nextjs-auth0';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import { ReactNotifications } from 'react-notifications-component';
import TagManager from 'react-gtm-module';
import { gtmVirtualPageView } from '@lib/gtm';

import client from '@lib/apolloClient';
import ErrorBoundary from '@components/error-boundary';
import ChatWidget from '@components/chat-widget';
import { wrapper } from '../store';
import { fetchSavedListings, fetchUser } from '../store/slices/userSlice';
import '../styles/libs/sanitize.scss';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/global-styles.scss';
import '../styles/global-utilities.scss';
import '../styles/notification.scss';

import localFont from 'next/font/local';

const gothamBook = localFont({
  fallback: ['system-ui', 'sans-serif'],
  src: '../fonts/Gotham-Book.woff2',
  style: 'normal',
  weight: 'normal'
});

const gothamBold = localFont({
  fallback: ['system-ui', 'sans-serif'],
  src: '../fonts/Gotham-Bold.woff2',
  style: 'normal',
  weight: 'bold'
});

const gothamMedium = localFont({
  fallback: ['system-ui', 'sans-serif'],
  src: '../fonts/Gotham-Medium.woff2',
  style: 'normal',
  weight: '400'
});

const ttJenevers = localFont({
  fallback: ['system-ui', 'sans-serif'],
  src: '../fonts/TTJenevers-Regular.woff2',
  style: 'normal',
  weight: 'normal'
});

const MyApp = ({ Component, pageProps }) => {
  const { user } = pageProps;
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.user.authenticated);
  const router = useRouter();

  useEffect(() => {
    // initiate GTM
    TagManager.initialize({
      gtmId: 'GTM-MQ6LP4L'
    });

    dispatch(fetchUser);
    if (authenticated) {
      dispatch(fetchSavedListings);
    }

    Router.events.on('routeChangeComplete', (url) => {
      document.cookie = `returnTo=${url}; path=/`;
    });
  }, [authenticated]);

  useEffect(() => {
    const mainDataLayer = {
      url: router.pathname
    };

    gtmVirtualPageView(mainDataLayer);
  }, [router.pathname]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
      </Head>
      <style jsx global>{`
        :root {
          --font-family-base: ${gothamBook.style.fontFamily};
          --font-family-bold: ${gothamBold.style.fontFamily};
          --font-family-medium: ${gothamMedium.style.fontFamily};
          --font-family-headings: ${ttJenevers.style.fontFamily};
        }
      `}</style>

      <UserProvider user={user}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <ChatWidget />
            <ReactNotifications />
            <Component {...pageProps} />
          </ApolloProvider>
        </ErrorBoundary>
      </UserProvider>
    </>
  );
};

export default wrapper.withRedux(MyApp);
