export default {
  props: {
    office: {
      address: '8145 Beechmont Avenue',
      city: 'Cincinnati',
      email: 'lizj@sibcycline.com',
      image: 'https://online.sibcycline.com/images/offices/AN.jpg',
      name: 'Anderson',
      phone: '513-474-4800',
      state: 'OH',
      zipCode: '45255-4241'
    }
  }
};
