const links = [
  { label: 'Vestibulum vitae', url: '#' },
  { label: 'Pellentesque tempor venenatis', url: '#' },
  { label: 'Fusce molestie', url: '#' },
  { label: 'Etiam in lacinia dolor (External)', url: 'https://www.sibcycline.com/' }
];

export default {
  links: {
    links: links
  },
  props: {
    links: links
  },
  withArrowIcons: {
    containsArrowIcon: true,
    links: links
  }
};
