export default (input) => {
  let inputCategories = input.categoryIds || input.categoryId;
  if (input.referencesCollection) {
    inputCategories = input.referencesCollection.items.map((cat) => cat.categoryId);
  }
  const categoryArray = Array.isArray(inputCategories) ? inputCategories : [inputCategories];
  return {
    ...input,
    categoryIds: categoryArray
  };
};
