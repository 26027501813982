// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Button = (props) => <Component {...transform(props)} />;

export default Button;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
