import Image from '../';
import PropTypes from 'prop-types';

const BlockImage = (props) => {
  const { image, maxWidth, alignment, link } = props;

  const styles = {
    display: 'flex',
    justifyContent: alignment,
    width: '100%'
  };

  return (
    <div style={styles}>
      <div style={{ maxWidth: maxWidth }}>
        <Image image={image} url={link} />
      </div>
    </div>
  );
};

BlockImage.propTypes = {
  /**
   * Left, Center, Right alignment of the image
   */
  alignment: PropTypes.oneOf(['left', 'center', 'right']),

  /**
   * Specifies the image object
   */
  image: PropTypes.shape({
    /**
     * Specifies the alt attribute
     */
    alt: PropTypes.string,

    /**
     * Specifies the height
     */
    height: PropTypes.number,

    /**
     * Specifies the image source
     */
    src: PropTypes.string,

    /**
     * Specifies the width
     */
    width: PropTypes.number
  }),

  /**
   * URL if the image links to another page
   */
  link: PropTypes.string,

  /**
   * Specifies the max width of the image
   */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BlockImage.defaultProps = {
  alignment: 'center',
  maxWidth: '100%'
};

export default BlockImage;
