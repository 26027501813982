// ---------------------------------------------------------

import Component from './component';

// ---------------------------------------------------------

const ChatWidget = (props) => <Component {...props} />;

export default ChatWidget;

// ---------------------------------------------------------

export { Component as component };
