const transformed = (input) => {
  return {
    ...input,
    link: {
      icon: input.linkIcon || input.icon,
      label: input.linkLabel || input.label,
      url: input.linkUrl || input.url
    }
  };
};

export default transformed;
