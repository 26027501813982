import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Button from '@components/button';
import Link from '@components/link';
import { Dropdown } from './dropdown';

// ---------------------------------------------------------

import { is_active, nav_button, navigation, background_is_white } from './styles.module.scss';

// ---------------------------------------------------------

const Navigation = (props) => {
  let { className, navigationLinks = [], isFullNavigation, menuIsOpen, collection } = props;

  // ---------------------------------------------------------

  const classes = classNames(navigation, className, {
    [background_is_white]: collection !== 'Main' && isFullNavigation
  });

  // ---------------------------------------------------------

  return (
    <ul className={classes}>
      {navigationLinks.map((link) => {
        if (link.subLinks) {
          return (
            <li key={`${link.label}-dropdown`}>
              <Dropdown
                isFullNavigation={isFullNavigation}
                items={link.subLinks}
                label={link.label}
                menuIsOpen={menuIsOpen}
              />
            </li>
          );
        } else if (link.button) {
          return (
            <li className={nav_button} key={`${link.label}-button`}>
              <Button className={link.className} theme={link.theme} url={link.url}>
                {link.label}
              </Button>
            </li>
          );
        } else {
          return (
            <li key={`${link.label}-link`}>
              <Link
                activeClassName={is_active}
                className={link.className}
                title={link.title}
                url={link.url}
              >
                {link.label}
              </Link>
            </li>
          );
        }
      })}
    </ul>
  );
};

Navigation.propTypes = {
  /**
   * Specifies if the full navigation should be rendered
   */
  isFullNavigation: PropTypes.bool,
  /**
   * Specifies if the mobile menu is open
   */
  menuIsOpen: PropTypes.bool,

  collection: PropTypes.string,

  /**
   * An array of links objects used to build the menu
   */
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.bool,
      className: PropTypes.string,
      label: PropTypes.string,
      subLinks: PropTypes.arrayOf(
        PropTypes.shape({
          className: PropTypes.string,
          heading: PropTypes.string,
          label: PropTypes.string,
          url: PropTypes.string
        })
      ),
      url: PropTypes.string
    })
  ).isRequired
};

Navigation.defaultProps = {};

export default Navigation;
