import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isSibcyListing: false
};

export const miscSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (!action.payload.miscValues) {
        return state;
      }
      state = action.payload.miscValues;
    }
  },
  initialState,
  name: 'miscValues',
  reducers: {
    updateIsSibcyListingFilter: (state, action) => {
      let { group, value } = action.payload;
      state[group] = value;
    }
  }
});

export const { updateIsSibcyListingFilter } = miscSlice.actions;

export default miscSlice.reducer;
