import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import useWindowSize from '@lib/window-size';

// ---------------------------------------------------------

import Grid from '@layout/grid';
import Image from '@components/image';

// ---------------------------------------------------------

import {
  container_header,
  header_description_container,
  container_description,
  container_alignment_center,
  container_alignment_left,
  container_alignment_right,
  container_content,
  container,
  content_box_shadow,
  remove_bottom_spacing,
  spacing_large,
  spacing_medium,
  spacing_none,
  spacing_small,
  text_alignment_center,
  text_alignment_left,
  text_alignment_right,
  theme_background_is_black,
  theme_background_is_gray,
  theme_background_is_white,
  theme_background_gray_to_white,
  theme_has_background_image_with_content_box_shadow,
  theme_has_white_content_box,
  theme_has_grey_content_box,
  width_fixed,
  width_fixed_large,
  width_fixed_medium,
  width_full,
  header_spacing_none,
  header_spacing_small,
  header_spacing_medium,
  header_spacing_large,
  no_overflow,
  no_padding,
  background_image
} from './styles.module.scss';

// ---------------------------------------------------------

const spacingOptions = {
  large: spacing_large,
  medium: spacing_medium,
  none: spacing_none,
  small: spacing_small
};

const textAlignmentOptions = {
  center: text_alignment_center,
  left: text_alignment_left,
  right: text_alignment_right
};

const textAlignmentContainerOptions = {
  center: container_alignment_center,
  left: container_alignment_left,
  right: container_alignment_right
};

const themeOptions = {
  'background-gray-to-white': theme_background_gray_to_white,
  'background-is-black': theme_background_is_black,
  'background-is-gray': theme_background_is_gray,
  'background-is-white': theme_background_is_white,
  'has-background-image-with-content-box-shadow':
    theme_has_background_image_with_content_box_shadow,
  'has-grey-content-box': theme_has_grey_content_box,
  'has-white-content-box': theme_has_white_content_box,
  none: null
};

const widthOptions = {
  fixed: width_fixed,
  full: width_full,
  large: width_fixed_large,
  medium: width_fixed_medium
};

const headerSpacingOptions = {
  large: header_spacing_large,
  medium: header_spacing_medium,
  none: header_spacing_none,
  small: header_spacing_small
};
// ---------------------------------------------------------

const Container = (props) => {
  const {
    backgroundImage,
    backgroundImageLayout,
    backgroundImageClassName,
    children,
    className,
    components,
    contentClassName,
    description,
    gridOptions,
    header,
    priority,
    removeBottomSpacing,
    spacing,
    textAlignment,
    theme,
    width,
    noOverflow,
    headerSpacing,
    noPadding
  } = props;

  const hasChildren = React.Children.count(children) > 0;

  // ---------------------------------------------------------

  const classes = classNames(container, {
    [className]: className,
    [themeOptions[theme]]: themeOptions[theme],
    [no_padding]: noPadding
  });

  const contentClasses = classNames(container_content, {
    [contentClassName]: contentClassName,
    [spacingOptions[spacing]]: spacingOptions[spacing],
    [no_overflow]: noOverflow,
    [textAlignmentOptions[textAlignment]]: textAlignmentOptions[textAlignment],
    [widthOptions[width]]: widthOptions[width],
    [remove_bottom_spacing]: removeBottomSpacing,
    [textAlignmentContainerOptions[textAlignment]]: textAlignmentContainerOptions[textAlignment]
  });

  const headerClasses = classNames(header_description_container, {
    [headerSpacingOptions[headerSpacing]]: headerSpacingOptions[headerSpacing]
  });

  // ---------------------------------------------------------

  const mediaIsSmall = useWindowSize().width >= 720;

  // ---------------------------------------------------------

  const contentWithBoxShadow = theme === 'has-background-image-with-content-box-shadow';

  // ---------------------------------------------------------

  const background = (
    <Image
      className={classNames(background_image, {
        [backgroundImageClassName]: backgroundImageClassName
      })}
      backgroundImage={true}
      image={backgroundImage || backgroundImage?.image}
      layout={backgroundImageLayout}
      objectFit="cover"
      priority={priority}
      sizes="100vw"
      quality={75}
    />
  );

  let hasBackgroundImage = backgroundImage;

  if (contentWithBoxShadow && !mediaIsSmall) {
    hasBackgroundImage = null;
  }

  return (
    <div className={classes}>
      {hasBackgroundImage && background}

      <div className={contentClasses}>
        <div className={contentWithBoxShadow ? content_box_shadow : null}>
          {header && (
            <div className={headerClasses}>
              {header && <h2 className={container_header}>{header}</h2>}
              {description && (
                <ReactMarkdown className={container_description}>{description}</ReactMarkdown>
              )}
            </div>
          )}

          {hasChildren
            ? children && <Grid {...gridOptions}>{children}</Grid>
            : components && <Grid {...gridOptions} components={components} />}
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {
  /**
   * Specifies the image url and alt text for the background image
   */
  backgroundImage: PropTypes.object,

  /**
   * Specifies the layout for the background image
   */
  backgroundImageLayout: PropTypes.string,

  /**
   * Contains components that get passed on to the <Container />
   */
  children: PropTypes.node,

  /**
   * An array of components that get passed on to <Component />
   */
  components: PropTypes.arrayOf(PropTypes.object),

  description: PropTypes.string,

  /**
   * Specifies options that get passed into the grid component.
   */
  gridOptions: PropTypes.shape({
    gutter: PropTypes.string,
    layout: PropTypes.string
  }),

  /**
   * Specifies the content of the container header
   */
  header: PropTypes.string,

  /**
   * Specifies the spacing between the header & the content for the grid
   */
  headerSpacing: PropTypes.string,

  /**
   * Specifies if the overflow hidden should be removed from the container
   */
  noOverflow: PropTypes.bool,

  /**
   * Specifies if the padding should be removed
   */
  noPadding: PropTypes.bool,

  /**
   * Specifies if the backgroundImage should be preloaded
   */
  priority: PropTypes.bool,

  /**
   * Specifies if the bottom spacing on the container should be removed
   */
  removeBottomSpacing: PropTypes.bool,

  /**
   * Specifies the space at the top and bottom of the container
   */
  spacing: PropTypes.oneOf(Object.keys(spacingOptions)),

  /**
   * Specifies the alignment of the container's text
   */
  textAlignment: PropTypes.oneOf(Object.keys(textAlignmentOptions)),

  /**
   * Specifies the theme of the container
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the width of container
   */
  width: PropTypes.oneOf(Object.keys(widthOptions))
};

Container.defaultProps = {
  backgroundImageLayout: 'fill',
  headerSpacing: 'small',
  rowPlacement: 'first',
  spacing: 'medium',
  theme: 'none',
  width: 'fixed'
};

export default Container;
