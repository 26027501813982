import dynamic from 'next/dynamic';

// ---------------------------------------------------------

import { useState } from 'react';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

const SearchByAgent = dynamic(() => import('@components/agent-finder/search-by-agent'));
const SearchByOffice = dynamic(() => import('@components/agent-finder/search-by-office'));
const TabSlider = dynamic(() => import('@components/tab-slider'));
const Container = dynamic(() => import('@layout/container'));

// ---------------------------------------------------------

import { container, tab_slider, content_container } from './styles.module.scss';

// ---------------------------------------------------------

const AgentFinder = (props) => {
  let { layout, header, description } = props;

  const [selectedSearch, setSelectedSearch] = useState(layout === 'Both' ? 'agent' : '');

  const idMap = {
    Agents: 'agent',
    Both: 'agent-finder',
    Offices: 'offices'
  };

  return (
    <Container layout="single">
      <div className={container} id={idMap[layout]}>
        {(header || description) && (
          <div className={content_container}>
            {header && <h3>{header}</h3>}
            {description && <p>{description}</p>}
          </div>
        )}

        {layout === 'Both' && (
          <div className={tab_slider}>
            <TabSlider
              items={[
                {
                  label: 'Search by agent',
                  value: 'agent'
                },
                {
                  label: 'Search by office',
                  value: 'office'
                }
              ]}
              defaultSelection={selectedSearch}
              setSelectedTabCallback={setSelectedSearch}
            />
          </div>
        )}

        {(selectedSearch === 'office' || layout === 'Offices') && <SearchByOffice />}
        {(selectedSearch === 'agent' || layout === 'Agents') && <SearchByAgent />}
      </div>
    </Container>
  );
};

AgentFinder.propTypes = {
  description: PropTypes.string,
  header: PropTypes.string,
  layout: PropTypes.oneOf(['Agents', 'Offices', 'Both']),
  showAgents: PropTypes.bool
};

AgentFinder.defaultProps = {
  layout: 'Both'
};

export default AgentFinder;
