import React from 'react';
import PropTypes from 'prop-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// ---------------------------------------------------------

import RenderBlocks from '@lib/cms/renderBlocks';
import { slugify } from '@lib/utils';

// ---------------------------------------------------------

import Accordion from '@components/accordion';
import AgentFinder from '@components/agent-finder';
import BlockImage from '@components/image/block-image';
import Button from '@components/button';
import Callout from '@components/callout';
import Container from '@layout/container';
import Image from '@components/image';
import Video from '@components/video';
import TopicCarousel from '@components/topic-carousel';
import FeaturedContent from '@components/featured-content';
import Category from '@components/category';
import FeaturedListings from '@components/featured-listings';

import { grouped_images, center_button } from './styles.module.scss';

// ---------------------------------------------------------

const Content = ({ content, className, isBlockCollection }) => {
  if (!content) return <div></div>;
  if (!content?.json) return <div className={className}>{content}</div>;

  const assetMap = new Map();
  const entryMap = new Map();

  if (content.links?.assets) {
    // create an asset map
    // loop through the assets and add them to the map
    for (const asset of content.links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  if (content.links?.entries) {
    for (const entry of content.links.entries.block) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        let text = '';
        for (var i = 0; i < node.content?.length; i++) {
          text = text + node.content[i]?.value;
        }

        return (
          <a
            href={node.data.uri}
            target={`${node.data.uri.match(/^http/) ? '_blank' : '_self'}`}
            rel={`${node.data.uri.match(/^http/) ? 'noopener noreferrer' : ''}`}
          >
            {text}
          </a>
        );
      },

      [BLOCKS.PARAGRAPH]: (node, children) => {
        // iterate over paragraph nodes, inspect child for groups of assets...
        const assets = node.content.every((c) => c.nodeType.includes('embedded-asset'));
        // if this node is an asset group, wrap it in a <section> so we can style it
        return assets ? (
          <section className={grouped_images}>{children}</section>
        ) : (
          <p>{children}</p>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id);

        if (asset !== undefined) {
          let { height, width } = asset;

          // default thumbnail sizes for grouped assets
          if (node.data?.grouped) {
            height = 350;
            width = 350;
          }

          const imageProps = {
            alt: asset?.description || asset?.alt,
            height: height,
            src: asset?.url || asset?.src,
            width: width
          };

          // render the asset accordingly
          return (
            <div>
              <Image layout="responsive" image={imageProps} />
            </div>
          );
        }
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        const text = children.map((child) => (typeof child === 'string' ? child : '')).join('');
        const id = slugify(text);
        return <h2 id={id}>{children}</h2>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = entryMap.get(node.data.target.sys.id);
        switch (entry?.__typename) {
          case 'TopicRecord':
          case 'TopicPage':
          case 'PostBlog':
          case 'Community':
            return <FeaturedContent {...entry} forceWhiteBackground></FeaturedContent>;
          case 'PageBuilderdevelopment':
            // eslint-disable-next-line
            const linkLabel = entry.category === 'Builder' ? 'View Builder' : 'View Development';
            return (
              <FeaturedContent
                {...entry}
                linkLabel={linkLabel}
                forceWhiteBackground
              ></FeaturedContent>
            );
          case 'CallOut':
            return <Callout {...entry} />;
          case 'Cta':
            return <Button {...entry} url={entry.link || entry.url} className={center_button} />;
          case 'ModuleImage':
            return (
              <Container layout="single">
                <BlockImage {...entry} />
              </Container>
            );
          case 'ModuleVideo':
            return (
              <Container layout="single">
                <Video {...entry} />
              </Container>
            );
          case 'AgentFinder':
            return <AgentFinder {...entry} />;
          case 'TopicCarousel':
            return <TopicCarousel {...entry} />;
          case 'Accordion':
            return (
              <Container layout="single">
                <Accordion {...entry}></Accordion>
              </Container>
            );
          case 'Grid':
            return RenderBlocks([entry]);
          case 'PostBlogCategory':
            return <Category {...entry} />;
          case 'Snippet':
            return <Container>{RenderBlocks([entry])}</Container>;
          case 'BlockFeaturedListings':
            return <FeaturedListings {...entry} />;
          default:
            return null;
        }
      }
    }
  };

  const recreateNode = (content) => {
    return {
      content: content,
      data: {},
      nodeType: 'document'
    };
  };

  /**
   * Iterates over rich text json array, grouping embedded asset nodes
   *
   * @param {Array} content - Rich text nodes
   * @returns Array
   */
  const groupAssets = (content) => {
    return content.reduce((_, b, i, arr) => {
      // get current and previous nodes
      const { nodeType: current } = b;
      let { nodeType: previous } = arr[i - 1];
      const key = 'embedded-asset';

      // if current node isn't an asset but the previous node is,
      // check if prior siblings are also assets...
      if (!current.includes(key) && previous.includes(key)) {
        // iterate backwards until we find the first asset node
        let j = i;
        while (previous.includes(key)) {
          j--;
          previous = arr[j]?.nodeType;
        }

        // calculate the first index of this group of assets
        let start = j + 1;
        if (i - start > 1) {
          // remove the asset nodes from the array
          const images = arr.splice(start, i - start);

          // reinsert the asset nodes as children of a paragraph element
          arr[start] = {
            content: images.map((img) => {
              img.data['grouped'] = true; // this property will be used above in renderNode()
              return img;
            }),
            data: {},
            nodeType: 'paragraph'
          };
        }
      }

      // always return the full array...
      return arr;
    });
  };

  if (isBlockCollection) {
    const separatedContent = [];
    let runningContent = [];

    content.json.content.forEach((node, idx) => {
      if (node.nodeType === 'embedded-entry-block') {
        if (runningContent.length > 0) {
          separatedContent.push({
            content: recreateNode(runningContent),
            hasContainer: true
          });
          runningContent = [];
        }
        separatedContent.push({
          content: node,
          hasContainer: false
        });
      } else {
        runningContent.push(node);
        if (idx === content.json.content.length - 1) {
          separatedContent.push({
            content: recreateNode(runningContent),
            hasContainer: true
          });
        }
      }
    });

    return (
      <>
        <div className={className}>
          {separatedContent.map(({ content, hasContainer }, idx) => {
            if (hasContainer) {
              return (
                <Container key={'content' + idx} layout="single">
                  {documentToReactComponents(content, options)}
                </Container>
              );
            } else {
              return <div key={'content' + idx}>{documentToReactComponents(content, options)}</div>;
            }
          })}
        </div>
      </>
    );
  }

  // crawl the content nodes and group the assets
  let components;
  try {
    const grouped = groupAssets(content.json.content);
    components = documentToReactComponents({ ...content.json, content: grouped }, options);
  } catch (e) {
    components = documentToReactComponents(content.json, options);
  }

  return <div className={className}>{components}</div>;
};

Content.propTypes = {
  /**
   * HTML string to be rendered to the page.
   */
  content: PropTypes.object,

  /**
   * If set to true, wraps all non 'block-embedded-entry' within a container"
   */
  isBlockCollection: PropTypes.bool
};

Content.defaultProps = {
  isBlockCollection: false
};

export default Content;
