import PropTypes from 'prop-types';

// ---------------------------------------------------------

import { video_container, video_player } from './styles.module.scss';

// ---------------------------------------------------------

const Video = ({ url, title }) => {
  const formatVideoUrl = () => {
    let platformUrl, regExp, videoId;
    if (url.includes('youtube') || url.includes('youtu.be')) {
      if (url.includes('embed')) {
        let deconstructedUrl = url.split('?')[0];
        return `${deconstructedUrl}?rel=0`;
      } else {
        platformUrl = 'https://www.youtube.com/embed';
        regExp = url.match(
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        );
        videoId = regExp && regExp[7].length === 11 ? regExp[7] : false;
        return `${platformUrl}/${videoId}?rel=0`;
      }
    } else if (url.includes('vimeo')) {
      if (url.includes('player')) {
        return url;
      } else {
        platformUrl = 'https://player.vimeo.com/video';
        regExp = url.match(
          /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
        );
        videoId = regExp && regExp[5] ? regExp[5] : false;
        return `${platformUrl}/${videoId}`;
      }
    } else {
      return url;
    }
  };

  return (
    <div className={video_container}>
      <iframe
        className={video_player}
        title={title}
        src={formatVideoUrl()}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  );
};

Video.propTypes = {
  /**
   * Specifies the title of the video
   */
  title: PropTypes.string,

  /**
   * Specifies the video URL (Provided by Sibcy Cline)
   */
  url: PropTypes.string
};

Video.defaultProps = {};

export default Video;
