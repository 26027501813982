import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

import Icon from '@components/icon';
import { updateSavedListing } from '@store/slices/userSlice';

// ---------------------------------------------------------

import {
  property_like_button_is_active,
  property_like_button,
  header_save,
  property_like_button_header,
  property_like_button_header_is_active
} from './styles.module.scss';

// ---------------------------------------------------------

export default function component(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { saved, authenticated } = useSelector((state) => state.user);

  const { id, header } = props;
  const [active, setActive] = useState(false);

  useEffect(() => {
    const ids = saved
      .filter(({ isFavorited }) => isFavorited === true)
      .map(({ listingId }) => listingId);

    setActive(ids.includes(id));
  }, [saved]);

  const onClick = async () => {
    if (authenticated) {
      let savedListing = saved.find(({ listingId }) => listingId === id);
      if (!savedListing) {
        savedListing = { listingId: id, savedListingId: 0 };
      }
      dispatch(updateSavedListing({ ...savedListing, isFavorited: !active }));
      setActive(savedListing?.isFavorited || false);
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = '/api/auth/login';
      } else {
        router.push('/api/auth/login');
      }
    }
  };

  const debouncedClickHandler = debounce(onClick, 250);

  return (
    <>
      <button
        className={classNames(property_like_button, {
          [property_like_button_header]: header,
          [property_like_button_header_is_active]: header && !active,
          [property_like_button_is_active]: active
        })}
        onClick={debouncedClickHandler}
        title="Property like button"
      >
        <Icon type="filters" name={header && !active ? 'like-outline' : 'like'} />
        {header && <p className={header_save}>Save</p>}
      </button>
    </>
  );
}
