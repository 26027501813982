export default {
  main_navigation: [
    {
      label: 'Buy',
      subLinks: [
        {
          children: [
            { forceAnchorTag: true, label: 'Browse A-Z', url: '/browse?search_type=all_listings' },
            { label: 'Coming Soon', url: '/coming-soon' },
            {
              forceAnchorTag: true,
              label: 'New Listings',
              url: '/browse'
            },
            {
              forceAnchorTag: true,
              label: 'Price Changes',
              url: '/browse?search_type=price_changes'
            },
            {
              forceAnchorTag: true,
              label: 'Open Houses',
              url: '/browse?search_type=open_houses'
            },
            {
              forceAnchorTag: true,
              label: 'New Construction',
              url: '/browse?search_type=new_construction'
            },
            { label: 'Premier Builders', url: '/builder' },
            {
              forceAnchorTag: true,
              label: 'View All Properties',
              url: '/results?status=Active&status=Pending'
            }
          ],
          heading: 'Browse'
        },
        {
          children: [{ label: 'National Search', url: 'https://www.leadingre.com/?coid=7884' }],
          heading: 'Expand Search'
        },
        {
          children: [
            { label: 'Get Pre-Approved', url: 'https://mortgage.sibcycline.com/Jumpstart' },
            { label: 'Buyer’s Guide', url: '/guidance/buyer-guide' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            { label: 'Find a Loan Officer', url: 'https://mortgage.sibcycline.com/FindLoanOfficer' }
          ],
          heading: 'Get Help'
        }
      ]
    },
    {
      label: 'Sell',
      subLinks: [
        {
          children: [
            { label: 'Sell your Home', url: '/sell-your-home' },
            { label: 'Seller’s Guide', url: '/guidance/seller-guide' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            { label: 'Home Services', url: 'https://homeservices.sibcycline.com/' }
          ]
        }
      ]
    },
    {
      label: 'Guidance',
      subLinks: [
        {
          children: [
            { label: 'School Links', url: '/schools' },
            { label: 'Community Guide', url: '/community' },
            { label: 'Buyer’s Guide', url: '/guidance/buyer-guide' },
            { label: 'Seller’s Guide', url: '/guidance/seller-guide' },
            { label: 'Regional Guide', url: '/guidance/regions' },
            { label: "What's Your Home Worth?", url: '/sell-your-home' },
            { label: 'Utility Connection', url: '/guidance/utility-connection' },
            { label: 'Blog', url: '/blog' }
          ]
        }
      ]
    },
    {
      label: 'Services',
      subLinks: [
        {
          children: [
            { label: 'Overview', url: '/services' },
            { label: 'Mortgage', url: 'https://mortgage.sibcycline.com/' },
            { label: 'Insurance', url: 'https://insurance.sibcycline.com/' },
            { label: 'Title', url: 'https://title.sibcycline.com/' },
            { label: 'Relocation', url: 'https://relocation.sibcycline.com/' },
            {
              label: 'U.S. Military on the Move',
              url: 'https://relocation.sibcycline.com/military-on-the-move'
            },
            { label: 'Home Services', url: 'https://homeservices.sibcycline.com/' },
            { label: 'Home Warranty', url: '/programs/home-warranty' }
          ]
        }
      ]
    },
    {
      label: 'Agents',
      subLinks: [
        {
          children: [
            { label: 'Offices', url: '/offices' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            {
              label: 'Find a Loan Officer',
              url: 'https://mortgage.sibcycline.com/FindLoanOfficer'
            },
            { label: 'Becoming an Agent', url: 'https://joinsibcycline.com/' }
          ]
        }
      ]
    }
  ],
  my_account: {
    label: 'My account',
    subLinks: [
      {
        children: [
          { label: 'Liked', url: '/account/liked' },
          { label: 'Searches', url: '/account/searches' },
          { label: 'Notes', url: '/account/notes' },
          { label: 'Notifications', url: '/account/notifications' },
          { label: 'Settings', url: '/account' },
          { label: 'Logout', url: '/api/auth/logout' }
        ]
      }
    ]
  }
};

export const mainNav = {
  main_navigation: [
    {
      label: 'Buy',
      subLinks: [
        {
          children: [
            { forceAnchorTag: true, label: 'Browse A-Z', url: '/browse?search_type=all_listings' },
            { label: 'Coming Soon', url: '/coming-soon' },
            {
              forceAnchorTag: true,
              label: 'New Listings',
              url: '/browse'
            },
            {
              forceAnchorTag: true,
              label: 'Price Changes',
              url: '/browse?search_type=price_changes'
            },
            {
              forceAnchorTag: true,
              label: 'Open Houses',
              url: '/browse?search_type=open_houses'
            },
            {
              forceAnchorTag: true,
              label: 'New Construction',
              url: '/browse?search_type=new_construction'
            },
            { label: 'Premier Builders', url: '/builder' },
            {
              forceAnchorTag: true,
              label: 'View All Properties',
              url: '/results?status=Active&status=Pending'
            }
          ],
          heading: 'Browse'
        },
        {
          children: [{ label: 'National Search', url: 'https://www.leadingre.com/?coid=7884' }],
          heading: 'Expand Search'
        },
        {
          children: [
            { label: 'Get Pre-Approved', url: 'https://mortgage.sibcycline.com/Jumpstart' },
            { label: 'Buyer’s Guide', url: '/guidance/buyer-guide' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            { label: 'Find a Loan Officer', url: 'https://mortgage.sibcycline.com/FindLoanOfficer' }
          ],
          heading: 'Get Help'
        }
      ]
    },
    {
      label: 'Sell',
      subLinks: [
        {
          children: [
            { label: 'Sell your Home', url: '/sell-your-home' },
            { label: 'Seller’s Guide', url: '/guidance/seller-guide' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            { label: 'Home Services', url: 'https://homeservices.sibcycline.com/' }
          ]
        }
      ]
    },
    {
      label: 'Guidance',
      subLinks: [
        {
          children: [
            { label: 'School Links', url: '/schools' },
            { label: 'Community Guide', url: '/community' },
            { label: 'Buyer’s Guide', url: '/guidance/buyer-guide' },
            { label: 'Seller’s Guide', url: '/guidance/seller-guide' },
            { label: 'Regional Guide', url: '/guidance/regions' },
            { label: "What's Your Home Worth?", url: '/sell-your-home' },
            { label: 'Utility Connection', url: '/guidance/utility-connection' },
            { label: 'Blog', url: '/blog' }
          ]
        }
      ]
    },
    {
      label: 'Services',
      subLinks: [
        {
          children: [
            { label: 'Overview', url: '/services' },
            { label: 'Mortgage', url: 'https://mortgage.sibcycline.com/' },
            { label: 'Insurance', url: 'https://insurance.sibcycline.com/' },
            { label: 'Title', url: 'https://title.sibcycline.com/' },
            { label: 'Relocation', url: 'https://relocation.sibcycline.com/' },
            {
              label: 'U.S. Military on the Move',
              url: 'https://relocation.sibcycline.com/military-on-the-move'
            },
            { label: 'Home Services', url: 'https://homeservices.sibcycline.com/' },
            { label: 'Home Warranty', url: '/programs/home-warranty' }
          ]
        }
      ]
    },
    {
      label: 'Agents',
      subLinks: [
        {
          children: [
            { label: 'Offices', url: '/offices' },
            { label: 'Find an Agent', url: '/find-an-agent' },
            {
              label: 'Find a Loan Officer',
              url: 'https://mortgage.sibcycline.com/FindLoanOfficer'
            },
            { label: 'Becoming an Agent', url: 'https://joinsibcycline.com/' }
          ]
        }
      ]
    }
  ],
  my_account: {
    label: 'My account',
    subLinks: [
      {
        children: [
          { label: 'Liked', url: '/account/liked' },
          { label: 'Searches', url: '/account/searches' },
          { label: 'Notes', url: '/account/notes' },
          { label: 'Notifications', url: '/account/notifications' },
          { label: 'Settings', url: '/account' },
          { label: 'Logout', url: '/api/auth/logout' }
        ]
      }
    ]
  }
};

export const relocationNav = {
  main_navigation: [
    {
      label: 'Personal Move',
      url: '/relocation/personal-move'
    },
    {
      label: 'Corporate Move',
      url: '/relocation/corporate-moves'
    },
    {
      label: 'Personal Move',
      url: '/relocation/meet-the-team'
    },
    {
      label: 'Moving Checklist',
      url: '/relocation/moving-checklist'
    },
    {
      label: '513-985-1238',
      url: 'tel:513-985-1238'
    }
  ]
};

export const mortgageNav = {
  main_navigation: [
    {
      label: 'Start with a loan officer',
      url: '/mortgage/find-loan-officer'
    },
    {
      label: 'Buy a home',
      url: '/mortgage/buy-a-home'
    },
    {
      label: 'Refinance',
      url: 'mortgage/refinance'
    },
    {
      label: 'FAQ',
      subLinks: [
        {
          children: [
            { label: 'How Much Can I Afford?', url: '/mortgage/how-much-can-i-afford' },
            { label: 'Monthly Payments?', url: '/mortgage/monthly-payments' },
            { label: 'Rent vs. Own?', url: '/mortgage/rent-vs-own' },
            { label: 'FHA Monthly Payments', url: '/mortgage/fha-monthly-payments' }
          ]
        }
      ]
    }
  ]
};
