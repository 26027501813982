import fetch from 'cross-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

let endpoint;

if (typeof window === 'undefined' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  // Production, Server Side
  endpoint = 'https://api.sibcycline.com/v2/graphql';
} else if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_LOCAL_ENV) {
  // Non-Production, Server Side
  endpoint = 'https://api-staging.sibcycline.com/v2/graphql';
} else {
  // Production & Non-Production, Client Side, Proxy
  endpoint = `https://${window.location.hostname}/graphql`;
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({ fetch, uri: endpoint })
});

export default client;
