// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Accordion = (props) => <Component {...transform(props)} />;

// ---------------------------------------------------------

export default Accordion;
