import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  basementTypes: [],
  bathrooms: 0,
  bedrooms: 0,
  garageSpaces: 0,
  heatingTypes: [],
  locations: [],
  maxAcres: 99999,
  maxPrice: 10000000,
  maxSquareFootage: 8000,
  minAcres: 0,
  minPrice: 0,
  minSquareFootage: 0,
  mustHaves: [],
  propertyStatus: [],
  propertySubSelectIds: [],
  propertyTypeId: 0,
  sortby: 'Highest Price',
  status: []
};

export const searchFiltersSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (!action.payload.searchFilters) {
        return state;
      }
      state = action.payload.searchFilters;
    }
  },
  initialState,
  name: 'searchFilters',
  reducers: {
    clearAllFilters: () => {
      return {
        ...initialState,
        propertySubSelectIds: ['1', '2'],
        propertyType: ['1'],
        status: ['Active', 'Pending']
      };
    },
    clearMultipleFilters: (state, action) => {
      action.payload.forEach((type) => {
        switch (type) {
          case 'status':
            state[type] = ['Active', 'Pending'];
            break;
          case 'propertySubSelectIds':
            state[type] = ['1', '2'];
            break;
          case 'propertyTypeId':
            state[type] = '1';
            break;
          default:
            state[type] = initialState[type];
        }
      });
    },
    initializeFiltersFromQuery: (state, action) => {
      const queryParams = action.payload;
      const initialized = { ...initialState, ...queryParams };

      return initialized;
    },
    updateAllFilters: (state, action) => {
      const { group, value } = action.payload;

      if (group === 'status') {
        if (value.includes('Sold') && value.length === 1) {
          state.daysSinceSold = 1095;
        } else {
          state.daysSinceSold = undefined;
        }
      }
      state[group] = value || [];
    },
    updateSingleFilter: (state, action) => {
      let { group, value } = action.payload;

      if (!isNaN(value)) {
        value = parseInt(value);
      }
      state[group] = value;
    }
  }
});

export const {
  updateAllFilters,
  updateSingleFilter,
  initializeFiltersFromQuery,
  clearMultipleFilters,
  clearAllFilters
} = searchFiltersSlice.actions;

export default searchFiltersSlice.reducer;
