const transformed = (input) => {
  const jumboThemesMap = {
    'Black Strip': 'bar',
    Centered: 'center',
    'White Box': 'card'
  };

  return {
    ...input,
    ctas: input.ctas?.items || input.ctas || [],
    theme: jumboThemesMap[input.theme] || input.theme
  };
};

export default transformed;
