import React, { Fragment } from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import { mainNav, relocationNav, homeServicesNav } from '@data/navigation-data';

// ---------------------------------------------------------

import { socialLinks, partners } from './fixtures';

// ---------------------------------------------------------

import { thisYear } from '@lib/utils';

// ---------------------------------------------------------

import Image from '@components/image';
import Link from '@components/link';
import Content from '@components/content';
import LinkList from './link-list';
import SocialNav from './social-nav';

// ---------------------------------------------------------

import {
  footer_wrap,
  background_image,
  bottom,
  content_area,
  description_container,
  footer,
  leadingre_partner_links_container,
  leadingre_partner_links,
  legal_links,
  legal,
  link_container,
  logo_container,
  logo_class,
  menu_links,
  partner_badges,
  partner_links_group,
  partner_links,
  partners_container,
  top,
  social_nav_mobile,
  social_nav_desktop
} from './styles.module.scss';

const collectionMap = {
  Main: mainNav,
  Mortgage: mainNav,
  Relocation: relocationNav,
  HomeServices: homeServicesNav,
  null: mainNav,
  undefined: mainNav
};

// ---------------------------------------------------------

const Footer = (props) => {
  let { collection, homePath } = props;

  let { copyrightText, description, rightImage, legalLinks, menuLinks, logo } =
    collectionMap[collection].footer || mainNav.footer;

  const { partnerBadges, partnerLinks } = partners;

  return (
    <LazyLoad offset={200} once className={footer_wrap}>
      <footer className={footer}>
        <div className={top}>
          <div
            className={background_image}
            style={{ backgroundImage: 'url("/images/footer-background.svg")' }}
          />

          <div className={content_area}>
            <div className={logo_container}>
              <Link
                className={logo_class}
                url={homePath ? homePath : '/'}
                ariaLabel="Sibcy Cline REALTORS®"
              >
                {logo ? (
                  <Image image={logo} />
                ) : (
                  <Image
                    image={{
                      alt: 'Sibcy Cline REALTORS®',
                      height: 51,
                      src: 'https://images.ctfassets.net/wzzep3ntdgx4/4LEtBgOhAZoITO3iV6KOw3/a2e488730ba1fd21f5f06b719ee5aa3f/logos-sibcy-horizontal-white.png',
                      width: 230
                    }}
                  />
                )}
              </Link>
              <SocialNav links={socialLinks} className={social_nav_mobile} />
            </div>

            <div className={description_container}>
              {description && <Content content={description} />}
              <SocialNav links={socialLinks} className={social_nav_desktop} />
            </div>

            <div className={link_container}>
              <LinkList className={menu_links} links={menuLinks} containsArrowIcon />
              <div className={leadingre_partner_links_container}>
                <Link className={leadingre_partner_links} url={rightImage?.url}>
                  <Image image={rightImage?.image} />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={bottom}>
          <div className={content_area}>
            {collection === 'Main' && (
              <div className={partners_container}>
                <div className={partner_links_group}>
                  <LinkList className={partner_links} links={partnerLinks} />
                </div>

                <div className={partner_badges}>
                  {partnerBadges.map((badge, index) => (
                    <Fragment key={index}>{badge.image}</Fragment>
                  ))}
                </div>
              </div>
            )}
            <div className={legal}>
              <small>{`© ${thisYear()} ${copyrightText}`}</small>
              <small>
                <LinkList className={legal_links} links={legalLinks} />
              </small>
            </div>
          </div>
        </div>
      </footer>
    </LazyLoad>
  );
};

Footer.propTypes = {
  collection: PropTypes.string,

  /**
   * Specifies the copyright
   */
  copyrightText: PropTypes.string,

  /**
   * Specifies the description
   */
  description: PropTypes.string,

  /**
   * Specifies an array of legal links that sit below the copyright info
   */
  legalLinks: PropTypes.array,

  /**
   * Specifies an array of menu items
   */
  menuLinks: PropTypes.array,

  /**
   * Specifies an array of Partner badges
   */
  partnerBadges: PropTypes.array,

  /**
   * Specifies an array of Partner links with logos
   */
  partnerLinks: PropTypes.object,

  rightImage: PropTypes.object,

  /**
   * Specifies an array of social links
   */
  socialLinks: PropTypes.array,
  homePath: PropTypes.string
};

Footer.defaultProps = {
  collection: 'Main'
};

export default Footer;
