import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Checkboxes from '@components/checkbox';
import Icon from '@components/icon';

// ---------------------------------------------------------

import {
  are_vertical,
  are_centered,
  checkboxes_container,
  header,
  label_text,
  icon_container,
  radio_button,
  radio_buttons,
  with_icon,
  with_label,
  with_label_inside,
  with_label_inside_square
} from './styles.module.scss';

const themeOptions = {
  'with-icon': with_icon,
  'with-label': with_label,
  'with-label-inside': with_label_inside,
  'with-label-inside-square': with_label_inside_square
};

// ---------------------------------------------------------

const RadioButton = (props) => {
  let {
    label,
    className,
    items,
    theme,
    center,
    selection,
    subSelections,
    onSubChange,
    onChange,
    isVertical
  } = props;

  const hasCheckboxes = items.some((item) => item?.checkboxes?.length > 0);

  // ---------------------------------------------------------

  const classes = classNames(className, radio_button, {
    [themeOptions[theme]]: themeOptions[theme]
  });

  // ---------------------------------------------------------

  return (
    <div>
      {label && <span className={header}>{label}</span>}
      <div
        className={classNames(radio_buttons, {
          [are_centered]: center,
          [are_vertical]: isVertical || hasCheckboxes
        })}
      >
        {items.map((item, i) => {
          const itemIsSelected = item.value == selection;

          return (
            <label className={classes} key={`${item.label}-${i}`}>
              <input
                aria-checked={itemIsSelected}
                checked={itemIsSelected}
                onChange={(e) => onChange(e.target.value)}
                type="radio"
                value={item.value}
              />
              <div>
                {theme === 'with-icon' && (
                  <div className={icon_container}>
                    <Icon type="filters" name={item.icon} />
                  </div>
                )}
                <span className={label_text}>{item.label}</span>

                {(item.checkboxes || subSelections) && itemIsSelected && (
                  <Checkboxes
                    className={checkboxes_container}
                    items={item.checkboxes}
                    selections={subSelections}
                    onChange={onSubChange}
                    selectAllLabel={null}
                  />
                )}
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

RadioButton.propTypes = {
  /**
   * Specifies if the radio buttons have additional options. eg. checkboxes
   */
  additionalOptions: PropTypes.bool,

  /**
   *  Specifies the additionalOptions items that are checked.
   */
  additionalOptionsCallback: PropTypes.func,

  /** Should the callback run on initial component mount */
  callBackOnInit: PropTypes.bool,

  /**
   * Specifies if the radio buttons are center
   */
  center: PropTypes.bool,

  /**
   * Specifies the items that are checked.
   */
  checkedCallback: PropTypes.func,

  /**
   * Specifies if the state of the radio-button should be cleared.
   */
  clearFilters: PropTypes.bool,

  /**
   * Specifies the icon of the 'any' option that clears the filter.
   */
  clearIcon: PropTypes.string,

  /**
   * Specifies the label of the 'any' option that clears the filter. Defaults to "Any".
   */
  clearLabel: PropTypes.string,

  /**
   * Specifies the value of the 'any' option that clears the filter (optional)
   */
  clearValue: PropTypes.any,

  /**
   * Specifies the default selected checkboxes if decided by passed down prop
   */
  defaultCheckboxes: PropTypes.array,

  /**
   * Specifies the defaultselection, if selected
   */
  defaultSelection: PropTypes.any,

  /**
   * Specifies the name of the group of the radio buttons
   */
  groupName: PropTypes.string,

  /**
   * An optional heading to appear above the Radio Buttons
   */
  heading: PropTypes.string,

  /**
   * Specifies whether the radiobuttons appear vertically (default horizontal)
   */
  isVertical: PropTypes.bool,

  /**
   * Specifies the radio buttons.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Specifies if the item has additional checkboxes
       */
      checkboxes: PropTypes.array,

      /**
       * Specifies the icon being used in the radio button.
       */
      icon: PropTypes.string,

      /**
       * Specifies the label of the radio button.
       */
      label: PropTypes.string,

      /**
       * Specifies the value of the radio button.
       */
      value: PropTypes.any
    })
  ),

  /**
   * An optional label to appear above the radio buttons
   */
  label: PropTypes.string,

  /**
   * Updates the item that is selected.
   */
  onChange: PropTypes.func,

  /**
   * Updates the sub (checkbox) items that are selected
   */
  onSubChange: PropTypes.func,

  /**
   * Specifies the item that is selected
   */
  selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Subselections for the checkboxes that can be nested within the radio buttons
   */
  subSelections: PropTypes.array,

  /**
   * Specifies the theme. (label_inside, large_with_icon)
   */
  theme: PropTypes.string,

  /**
   * Specifies whether the component state is handled with redux, mostly
   * for storybook testing purposes
   */
  withRedux: PropTypes.bool
};

RadioButton.defaultProps = {
  isVertical: false,
  theme: 'with-label',
  withRedux: false
};

export default RadioButton;
