// ---------------------------------------------------------

import Component from './component';

// ---------------------------------------------------------

const Content = (props) => <Component {...props} />;

export default Content;

// ---------------------------------------------------------

export { Component as component };
