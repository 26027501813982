import { parse, format, isBefore } from 'date-fns';
import { isDaylightSavingTime, determineYear } from './utils';

export const formatEventDatesToISO = (input) => {
  const parts = input.split(', '); // Splits "SUN, APR 21, 12PM - 1:30PM" into parts

  if (parts.length < 3) {
    console.error('Incorrect format. Expected input: "Day, MMM DD, startTime - endTime"');
    return {};
  }

  const datePart = parts[1].trim(); // "APR 21"
  const timeRange = parts[2].trim(); // "12PM - 1:30PM"
  const [startTime, endTime] = timeRange.split(' - ').map((time) => time.trim());

  // Handles both hours only and hours:minutes formats
  const parseFormat = (time) => {
    return time.includes(':') ? 'MMM dd yyyy h:mma' : 'MMM dd yyyy hha';
  };

  const year = determineYear(datePart);
  const fullStartDateTimeStr = `${datePart} ${year} ${startTime}`;
  const fullEndDateTimeStr = `${datePart} ${year} ${endTime}`;

  try {
    const startDateTime = parse(fullStartDateTimeStr, parseFormat(startTime), new Date());
    const endDateTime = parse(fullEndDateTimeStr, parseFormat(endTime), new Date());

    if (isNaN(startDateTime) || isNaN(endDateTime)) {
      console.error('Failed to parse one or both of the times.');
      return {};
    }

    // Check if the start date is in the past
    const now = new Date();
    if (isBefore(startDateTime, now)) {
      console.error('The event date has already passed.');
      return {};
    }

    const isStartDateDST = isDaylightSavingTime(startDateTime);
    const startDateOffset = isStartDateDST ? '-04:00' : '-05:00';

    const isEndDateDST = isDaylightSavingTime(endDateTime);
    const endDateOffset = isEndDateDST ? '-04:00' : '-05:00';

    const startDateISO = format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss");
    const endDateISO = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");

    return {
      startDate: `${startDateISO}${startDateOffset}`,
      endDate: `${endDateISO}${endDateOffset}`
    };
  } catch (error) {
    console.error('Date processing error:', error.message);
    throw error; // Re-throw the error after logging
  }
};

export const generateRealEstateSchema = (propertyData) => {
  const {
    address,
    city,
    state,
    canonicalUrl,
    marketingRemarks,
    zip,
    price,
    mainPhoto,
    openHouses,
    propertyTypeDisplay,
    videoUrl,
    onMarketDate,
    latitude,
    longitude
  } = propertyData;

  const listingName = `${address}, ${city}, ${state} ${zip}`;

  let graph = [
    {
      '@type': 'RealEstateListing',
      additionalType: propertyTypeDisplay,
      description: marketingRemarks,
      image: mainPhoto?.midSizeImageUrl,
      name: listingName,
      offers: {
        '@type': 'Offer',
        price: price,
        priceCurrency: 'USD'
      },
      url: canonicalUrl,
      location: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: address,
          addressLocality: city,
          addressRegion: state,
          postalCode: zip,
          addressCountry: 'USA'
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: latitude,
          longitude: longitude
        }
      }
    }
  ];

  if (videoUrl) {
    graph[0].video = {
      '@type': 'VideoObject',
      description: `Watch the video tour of ${listingName}!`,
      name: `Video Tour of ${listingName}`,
      thumbnailUrl: videoUrl,
      contentUrl: videoUrl,
      embedUrl: videoUrl,
      // Logic below is to handle listings not yet on the market with videos
      uploadDate:
        onMarketDate && isBefore(new Date(onMarketDate), new Date())
          ? new Date(onMarketDate).toISOString()
          : new Date().toISOString()
    };
  }

  if (openHouses !== '' && openHouses.length > 0) {
    const openHouseEvents = openHouses
      .map((openHouse) => {
        const openHouseTimes = formatEventDatesToISO(openHouse.formattedDateTimeDisplay);

        if (!openHouseTimes.startDate || !openHouseTimes.endDate) {
          return null;
        }

        return {
          '@type': 'Event',
          endDate: openHouseTimes.endDate,
          location: {
            '@type': 'Place',
            address: {
              '@type': 'PostalAddress',
              addressCountry: 'USA',
              addressLocality: city,
              addressRegion: state,
              postalCode: zip,
              streetAddress: address
            },
            name: listingName
          },
          name: `Open House at ${listingName}`,
          offers: {
            '@type': 'Offer',
            price: price,
            priceCurrency: 'USD'
          },
          startDate: openHouseTimes.startDate
        };
      })
      .filter((event) => event !== null);

    graph = graph.concat(openHouseEvents);
  }

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': graph
  });
};

export const generateWebsiteSchema = (meta) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Sibcy Cline REALTORS®',
    url: `${meta.url}`,
    publisher: {
      '@type': 'Organization',
      name: 'Sibcy Cline REALTORS®',
      logo: {
        '@type': 'ImageObject',
        url: `${meta.imageUrl}`
      }
    },
    inLanguage: ['en'],
    accessibilityFeature: 'ARIA landmarks',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          'https://www.sibcycline.com/results?bathrooms={bathrooms}&bedrooms={bedrooms}&garageSpaces={garageSpaces}&maxAcres={maxAcres}&maxPrice={maxPrice}&minAcres={minAcres}&minPrice={minPrice}&minSquareFootage={minSquareFootage}&propertySubSelectIds={propertySubSelectIds}&propertyTypeId={propertyTypeId}&sortby={sort_order}&status={status}&page={page}&hasBasementTypePartiallyFinished={hasBasementTypePartiallyFinished}&hasBasementTypeUnfinished={hasBasementTypeUnfinished}&hasBasementTypeFinished={hasBasementTypeFinished}&hasHeatTypeElectric={hasHeatTypeElectric}&hasHeatTypeForcedAir={hasHeatTypeForcedAir}&hasHeatTypeGravity={hasHeatTypeGravity}&hasHeatTypeGeothermal={hasHeatTypeGeothermal}&hasHeatTypeHeatPump={hasHeatTypeHeatPump}&hasHeatTypeSteam={hasHeatTypeSteam}&hasHeatTypeGas={hasHeatTypeGas}&hasLevel1Bedroom={hasLevel1Bedroom}&hasLevel1Bath={hasLevel1Bath}&hasSwimmingPool={hasSwimmingPool}&hasCentralAir={hasCentralAir}&hasFamilyRoom={hasFamilyRoom}&hasFormalDiningRoom={hasFormalDiningRoom}&showOnlyNewConstructions={showOnlyNewConstructions}&showOnlyOpenHouses={showOnlyOpenHouses}&daysSinceNew={daysSinceNew}&daysSinceLastPriceChange={daysSinceLastPriceChange}&counties={counties}&places={places}&schoolDistricts={schoolDistricts}&zip={zip}'
      },
      'query-input': [
        { '@type': 'PropertyValueSpecification', valueName: 'bathrooms', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'bedrooms', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'garageSpaces', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'maxAcres', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'maxPrice', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'minAcres', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'minPrice', valueRequired: false },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'minSquareFootage',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'propertySubSelectIds',
          valueRequired: false,
          multipleValues: true
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'propertyTypeId',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'sort_order',
          valueRequired: false,
          defaultValue: 'Price Change'
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'status',
          valueRequired: false,
          multipleValues: true
        },
        { '@type': 'PropertyValueSpecification', valueName: 'page', valueRequired: false },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasBasementTypePartiallyFinished',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasBasementTypeUnfinished',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasBasementTypeFinished',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeElectric',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeForcedAir',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeGravity',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeGeothermal',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeHeatPump',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeSteam',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasHeatTypeGas',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasLevel1Bedroom',
          valueRequired: false
        },
        { '@type': 'PropertyValueSpecification', valueName: 'hasLevel1Bath', valueRequired: false },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasSwimmingPool',
          valueRequired: false
        },
        { '@type': 'PropertyValueSpecification', valueName: 'hasCentralAir', valueRequired: false },
        { '@type': 'PropertyValueSpecification', valueName: 'hasFamilyRoom', valueRequired: false },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'hasFormalDiningRoom',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'showOnlyNewConstructions',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'showOnlyOpenHouses',
          valueRequired: false
        },
        { '@type': 'PropertyValueSpecification', valueName: 'daysSinceNew', valueRequired: false },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'daysSinceLastPriceChange',
          valueRequired: false
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'counties',
          valueRequired: false,
          multipleValues: true
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'places',
          valueRequired: false,
          multipleValues: true
        },
        {
          '@type': 'PropertyValueSpecification',
          valueName: 'schoolDistricts',
          valueRequired: false,
          multipleValues: true
        },
        { '@type': 'PropertyValueSpecification', valueName: 'zip', valueRequired: false }
      ]
    },
    sameAs: [
      'https://www.facebook.com/SibcyClineRealtors',
      'https://www.twitter.com/sibcycline/',
      'https://www.instagram.com/sibcycline/',
      'https://www.linkedin.com/company/sibcy-cline'
    ]
  });
};

export const generateAboutSchema = (meta) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'AboutPage',
    url: meta.url
  });
};

export const generateContactSchema = (meta) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    url: meta.url
  });
};
