// ---------------------------------------------------------

import dynamic from 'next/dynamic';

// ---------------------------------------------------------

const Container = dynamic(() => import('@layout/container'));
const Grid = dynamic(() => import('@layout/grid'));

// ---------------------------------------------------------

import Accordion from '@components/accordion';
import AgentFinder from '@components/agent-finder';
import ArticleCard from '@components/article-card';
import Button from '@components/button';
import BuysideWidget from '@components/buyside-widget';
import Carousel from '@components/carousel';
import Category from '@components/category';
import FeaturedContent from '@components/featured-content';
import Form from '@components/forms';
import Image from '@components/image/block-image';
import Input from '@components/inputs/input';
import Jumbotron from '@components/jumbotron';
import PostList from '@components/post-list';
import Quad from '@components/quad';
import Select from '@components/select';
import SellWithSibcy from '@components/sell-with-sibcy';
import TextBlock from '@components/text-block';
import Video from '@components/video';

// ---------------------------------------------------------

export default {
  Accordion: Accordion,
  AgentFinder: AgentFinder,
  ArticleCard: ArticleCard,
  Button: Button,
  BuysideWidget: BuysideWidget,
  Carousel: Carousel,
  Category: Category,
  Container: Container,
  Form: Form,
  Grid: Grid,
  Image: Image,
  Input: Input,
  Jumbotron: Jumbotron,
  PostBlogCategory: Category,
  PostList: PostList,
  Quad: Quad,
  Select: Select,
  SellWithSibcy: SellWithSibcy,
  TextBlock: TextBlock,
  TopicPage: FeaturedContent,
  TopicRecord: FeaturedContent,
  Video: Video
};
