import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GQL from '@lib/cms/gql';
import { MOST_RECENT_BLOGS_QUERY } from '@lib/fragments';

// ---------------------------------------------------------

import Button from '@components/button';
import Image from '@components/image';
import Link from '@components/link';

// ---------------------------------------------------------

import {
  container,
  header,
  post_container,
  post_row,
  post_text,
  footer_link
} from './styles.module.scss';

// ---------------------------------------------------------

const PostList = (props) => {
  const { referencesCollection, limit, title, shouldNotFetch } = props;
  const [latestPosts, setLatestPosts] = useState([]);

  // ---------------------------------------------------------

  useEffect(() => {
    const fetchRecentBlogs = async () => {
      const {
        data: {
          postBlogCollection: { items }
        }
      } = await GQL.fetch(MOST_RECENT_BLOGS_QUERY, { limit }, false);

      setLatestPosts(items);
    };

    const references = referencesCollection?.items || referencesCollection;

    if (shouldNotFetch || (references && references.length > 0)) {
      setLatestPosts(references);
    } else {
      fetchRecentBlogs();
    }
  }, [referencesCollection]);

  const onlyBlogs = !latestPosts.every((item) => item.__typename === 'blog');

  const getPostUrl = (post) => {
    switch (post.__typename) {
      case 'TopicRecord':
        return post.linkUrl;
      case 'TopicPage':
        return `/${post.parentPage.toLowerCase()}/${post.slug}`;
      default:
        return `/blog/${post.slug}`;
    }
  };

  // ---------------------------------------------------------
  return (
    <div className={container}>
      <h2 className={header}>{title}</h2>
      <div className={post_container}>
        {latestPosts &&
          latestPosts.map((post, key) => {
            const linkUrl = getPostUrl(post);

            return (
              <div key={key} className={post_row}>
                <Link url={linkUrl}>
                  {post.featuredImage && (
                    <Image
                      image={{
                        height: 61,
                        src: post.featuredImage.url,
                        width: 75
                      }}
                      sizes="75px"
                      layout="fill"
                      quality={75}
                    />
                  )}
                </Link>

                {post.title && (
                  <div className={post_text}>
                    <Link url={linkUrl}>{post.title}</Link>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {onlyBlogs && (
        <div className={footer_link}>
          <Button
            label="See more posts"
            theme="red-text-only-with-caret"
            url="/blog/category/all"
          />
        </div>
      )}
    </div>
  );
};

PostList.propTypes = {
  /**
   * Specifices how many posts to pull in
   */
  limit: PropTypes.number,

  /**
   * Specifices the length of the truncation of the title
   */
  maxTitleLength: PropTypes.number,

  /**
   * A collection of posts to override the default blog collection
   */
  referencesCollection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object)
    })
  ]),

  /**
   * Boolean to prevent post list with featured to cause the component to dupe fetch
   */
  shouldNotFetch: PropTypes.bool,

  /**
   * Specifices the component header
   */
  title: PropTypes.string,
  /**
   * Specifices the post url
   */
  url: PropTypes.string
};

PostList.defaultProps = {
  fetching: false,
  limit: 4,
  maxTitleLength: 90
};

export default PostList;
