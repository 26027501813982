import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import { select, select_container } from './styles.module.scss';

// ---------------------------------------------------------

const Select = (props) => {
  let { className, label, options, id, onChange, selected, disabled } = props;

  const initialOption = options[0].value;
  const selectedOption = selected ?? initialOption;

  // -------------------------------------------------------

  const classes = classNames(select_container, {
    [className]: className
  });

  // -------------------------------------------------------

  return (
    <div className={classes}>
      <label htmlFor={id}>{label}</label>
      <div className={select}>
        <select
          id={id}
          onChange={(e) => onChange(e.target.value)}
          value={selectedOption}
          disabled={disabled}
        >
          {options.map((option, index) => {
            return (
              <option
                value={option.value}
                key={index}
                aria-selected={selectedOption === option.value}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  /**
   * Specifies the 'Any' label used in the select default
   */
  anyLabel: PropTypes.string,

  /**
   * Specifies the default option that is selected.
   */
  defaultSelection: PropTypes.string,

  /**
   * Specifies the select is disabled
   */
  disabled: PropTypes.bool,

  id: PropTypes.string,

  /**
   * Specifies the label of the select
   */
  label: PropTypes.string,

  /**
   * Callback function that fires when this elements selected value changes
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Specifies the options of the select
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  /**
   * Specifies the option that is selected.
   */
  selected: PropTypes.any,

  /**
   * Specifies the theme if any
   */
  theme: PropTypes.string
};

Select.defaultProps = {};

export default Select;
