// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const TextBlock = (props) => <Component {...transform(props)} />;

export default TextBlock;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
