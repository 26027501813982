// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Callout = (props) => <Component {...transform(props)} />;

export default Callout;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
