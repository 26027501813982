export const INITIAL_PARAMS = {
  bathrooms: 0,
  bedrooms: 0,
  counties: [],
  daysSinceLastPriceChange: 10000,
  daysSinceNew: 10000,
  garageSpaces: 0,
  hasBasementTypeFinished: false,
  hasBasementTypePartiallyFinished: false,
  hasBasementTypeUnfinished: false,
  hasCentralAir: false,
  hasFamilyRoom: false,
  hasFormalDiningRoom: false,
  hasHeatTypeElectric: false,
  hasHeatTypeForcedAir: false,
  hasHeatTypeGas: false,
  hasHeatTypeGeothermal: false,
  hasHeatTypeGravity: false,
  hasHeatTypeHeatPump: false,
  hasHeatTypeSteam: false,
  hasLevel1Bath: false,
  hasLevel1Bedroom: false,
  hasSwimmingPool: false,
  featured: null,
  maxAcres: 99999,
  maxPrice: 10000000,
  maxSquareFootage: 8000,
  minAcres: 0,
  minPrice: 0,
  minSquareFootage: 0,
  places: [],
  propertySubSelectIds: [],
  propertySubTypeIds: [],
  propertyTypeId: 0,
  schoolDistricts: [],
  showOnlyNewConstructions: false,
  showOnlyOpenHouses: false,
  skip: 0,
  sortBy: {
    price: 'DESC'
  },
  status: [],
  take: 15,
  zip: []
};

export const ignoreEntries = ['statusId', 'states', 'page', 'alerts'];

export const locationKeys = ['state', 'schoolDistrict', 'zip', 'county', 'city'];

export const operators = {
  acres: {
    gte: 'minAcres',
    lte: 'maxAcres'
  },
  bathroomCount: {
    gte: 'bathrooms'
  },
  bedroomCount: {
    gte: 'bedrooms'
  },
  city: 'places',
  county: 'counties',
  daysSinceNew: {
    lte: 'daysSinceNew'
  },
  hasInRangeOpenHouse: 'showOnlyOpenHouses',
  isNewConstruction: 'showOnlyNewConstructions',
  isIdxListing: 'featured',
  price: {
    gte: 'minPrice',
    lte: 'maxPrice'
  },
  propertySubSelectIds: 'propertySubSelectIds',
  propertyTypeId: 'propertyTypeId',
  schoolDistrict: 'schoolDistricts',
  squareFootage: {
    gte: 'minSquareFootage',
    lte: 'maxSquareFootage'
  },
  state: 'states'
};

export const heatingTypeMap = [
  'hasHeatTypeGas',
  'hasHeatTypeElectric',
  'hasHeatTypeSteam',
  'hasHeatTypeForcedAir',
  'hasHeatTypeHeatPump',
  'hasHeatTypeGravity',
  'hasHeatTypeGeothermal'
];

export const paramsShouldBeArray = ['status', 'propertySubSelectIds'];

export const paramsShouldBeInt = [
  'bedrooms',
  'bathrooms',
  'daysSinceNew',
  'daysSinceLastPriceChange',
  'maxSquareFootage',
  'minSquareFootage',
  'garageSpaces',
  'minPrice',
  'maxPrice',
  'maxAcres',
  'minAcres'
];

export const paramsShouldBeBool = [
  'showOnlyNewConstructions',
  'showOnlyOpenHouses',
  'hasBasementTypeFinished',
  'hasBasementTypePartiallyFinished',
  'hasBasementTypeUnfinished',
  'hasCentralAir',
  'hasFamilyRoom',
  'hasFormalDiningRoom',
  'hasHeatTypeElectric',
  'hasHeatTypeForcedAir',
  'hasHeatTypeGas',
  'hasHeatTypeGeothermal',
  'hasHeatTypeGravity',
  'hasHeatTypeHeatPump',
  'hasHeatTypeSteam',
  'hasLevel1Bath',
  'hasLevel1Bedroom',
  'hasSwimmingPool',
  'featured'
];

export const basementTypeMap = [
  'hasBasementTypeFinished',
  'hasBasementTypePartiallyFinished',
  'hasBasementTypeUnfinished'
];

export const propertyStatusMap = [
  'daysSinceLastPriceChange',
  'daysSinceNew',
  'showOnlyOpenHouses',
  'showOnlyNewConstructions',
  'featured'
];

export const mustHaveMap = [
  'hasLevel1Bedroom',
  'hasFormalDiningRoom',
  'hasLevel1Bath',
  'hasFamilyRoom',
  'hasSwimmingPool',
  'hasCentralAir'
];

export const flattenAndTransform = {
  basementTypes: {},
  heatingTypes: {},
  mustHaves: {},
  propertyStatus: {
    daysSinceLastPriceChange: 7,
    daysSinceNew: 7,
    showOnlyNewConstructions: true,
    showOnlyOpenHouses: true,
    featured: true
  }
};
