import { gql } from '@apollo/client';
export const CONTACT_ATTRIBUTES = `
fragment Contact on ListingContact {
  primaryContactEmail
  primaryContactLanguages
  primaryContactName
  primaryContactPhone
  primaryContactPhotoUrl
  primaryContactProfessionalTitle
  primaryContactWebsiteUrl
  secondaryContactEmail
  secondaryContactName
  secondaryContactPhone
  secondaryContactPhotoUrl
  secondaryContactWebsiteUrl
  secondaryContactProfessionalTitle
  secondaryContactLanguages
}`;

export const LISTING_ATTRIBUTES = `
  ${CONTACT_ATTRIBUTES}
  fragment Listing on FullListing {
    acres
    address
    agents {
      agentId
      email
      photoUrl
      isActiveSibcyAgent
      name
      phone
      position
    }
    alerts {
      text
      type
    }
    alertPills
    bathroomCount
    bedroomCount
    board
    builderSlug
    canonicalUrl
    city
    communitySlug
    communityFallbackSlug
    concessionsPercentage
    contact {
     ...Contact
    }
    county
    daysSinceNew
    daysSinceEntered
    daysSinceLastPriceChange
    dataRefreshed
    developmentSlug
    directions
    features (order: {name: ASC }) {
      name
      value
    }
    fullBathroomCount
    hasInRangeOpenHouse
    informationRequestDefaultComments
    isNewConstruction
    latitude
    listingCourtesyOf
    listingId
    listingUrl
    longitude
    mainPhoto {
      midSizeImageUrl
      title
    }
    mapLocationExternalLink
    marketingRemarks
    mlsCopyright {
      logoUrl
      description
    }
    mlsNumber
    onMarketDate
    openHouses {
      formattedDateTimeDisplay
    }
    partBathroomCount
    propertyTypeDisplay
    propertyTypeId
    photos(order: { position: ASC }) {
      midSizeImageUrl
      extraLargeImageUrl
      title
      extraLargeImageWidth
      extraLargeImageHeight
    }
    price
    priceFormatted
    rooms (order: {name: ASC }) {
      name
      description
    }
    sellerConcessions
    schoolDistrict
    similarListingIds
    sharingServicesCollection {
			shareName
			shareUrl
			shareLogo
		}
    squareFootage
    state
    status
    statusId
    streetName
    suburb
    videoUrl
    virtualTourUrl
    zip
  }
`;

export const SNIPPET_ATTRIBUTES = `
  fragment Snippet on Snippet {
    __typename
    sys { id }
    title
    template: component
    config
    referencesCollection (limit: 5) {
      items {
        __typename
        ... on PostBlogCategory {
          slug
          categoryId
          slug
        }
      }
    }
  }
`;

export const CTA_ATTRIBUTES = `
  fragment Cta on Cta {
    __typename
    theme
    label: title
    url: link
  }
`;

export const TOPIC_RECORD_ATTRIBUTES = `
  fragment TopicRecord on TopicRecord {
    excerpt
    parentPage: topic
    featuredImage {
      title
      url
      width
      height
    }
    linkLabel
    linkUrl
    title
  }
`;

export const TOPIC_PAGE_ATTRIBUTES = `
  fragment TopicPage on TopicPage {
    __typename
    linkUrl: slug
    title
    body: excerpt
    parentPage: topic
    image: featuredImage {
      title
      url
      width
      height
    }
    linkLabel
  }
`;

export const TOPIC_CAROUSEL_ATTRIBUTES = `
  fragment TopicCarousel on TopicCarousel {
    __typename
    sys { id }
    theme: style
    layout
    carouselHeader:header
    carouselDescription: description {
      json
    }
    autoPlay
    background:backgroundColor
    slidesCollection:contentCollection (limit: 10) {
      cards:items {
        __typename
        ...on PageBuilderdevelopment {
          title: builderDevelopmentName
          category
          serviceArea
          description: excerpt,
          featuredImage {
            alt: title
            url
            width
            height
          }
          slug
          linkLabel
        }
        ...on TopicRecord {
          sys { id }
          title
          description: excerpt
          featuredImage {
            title
            url
            width
            height
          }
          linkLabel
          linkUrl
        }
        ...on TopicPage{
          sys { id }
          title
          description:excerpt
          featuredImage {
            description
            url
            width
            height
          }
          topic
          slug
          linkLabel
        }
        ...on PostBlog {
          sys { id }
          slug
          title
          description: excerpt
          featuredImage {
            description
            url
            width
            height
          }
        }
      }
    }
  }
`;

export const IMAGE_ATTRIBUTES = `
  fragment Image on ModuleImage {
    __typename
    sys { id }
    title
    alignment
    maxWidth
    url: link
    image {
      alt: title
      url
      description
      width
      height
    }
  }
`;

export const VIDEO_ATTRIBUTES = `
  fragment Video on ModuleVideo {
    __typename
    sys { id }
    title
    url
  }
`;

export const AGENT_FINDER_ATTRIBUTES = `
  fragment AgentFinder on AgentFinder {
    __typename
    header
    description
    layout
  }
`;

export const JUMBOTRON_ATTRIBUTES = `
  fragment Jumbotron on Jumbotron {
    __typename
    ctas: ctasCollection (limit: 1) {
      items {
        ...Cta
      }
    }
    body: description
    image {
      url
      description
      alt: title
      width
      height
    }
    title: header
    theme
  }
`;

export const GRID_ATTRIBUTES = `
fragment Grid on Grid {
  __typename
  sys { id }
  title
  header
  headerSpacing
  description
  layout
  theme
  spacing
  width
  gutter
  verticalAlignment
  backgroundImage {
    title
    image {
      alt: title
      url
      height
      width
    }
  }
  removeBottomSpacing
  textAlignment
  blocksCollection (limit: 10) {
    items {
      __typename
      ...on Entry {
        unresolved: sys {
          id
        }
      }
    }
  }
}
`;

export const ARTICLE_CARD_ATTRIBUTES = `
  fragment ArticleCard on ArticleCard {
    __typename
    theme
    title: description
    icon: image {
      alt: title
      url
      height
      width
    }
    image: backgroundImage {
      alt: title
      url
      height
      width
    }
    linkLabel
    link
  }
`;

export const CALLOUT_ATTRIBUTES = `
  fragment Callout on CallOut {
    sys { id }
    title
    theme
      calloutBody: body {
        json
      }
      linkLabel
      linkUrl
      linkIcon
      stat1
      stat2
      stat3
      __typename
    }
`;

export const FEATURED_LISTINGS_BLOCK_ATTRIBUTES = `
  fragment BlockFeaturedListings on BlockFeaturedListings {
    __typename
    header
    seeAllLink
    resultsPageUrl
    theme
    limit
  }
`;

// TODO can linkedFrom be removed?
export const POST_BLOG_CATEGORY_ATTRIBUTES = `
fragment PostBlogCategory on PostBlogCategory {
  sys {
    id
  }
  title
  categoryId
  description
  headerImage {
    alt: title
    url
    height
    width
  }
  slug
  metaDescription
  metaImage {
    alt: description
    url
  }
}
`;

export const POST_BLOG_SHORT_ATTRIBUTES = `
  fragment PostBlogShort on PostBlog {
    sys {
      id
    }
    title
    slug
    excerpt
    label
    featuredImage {
      url
      width
      height
    }
    publishDate
    categories
  }
`;

export const POST_BLOG_ATTRIBUTES = `
  fragment PostBlog on PostBlog {
    sys {
      id
    }
    title
    slug
    excerpt
    pullQuote
    author
    publishDate
    sidebar {
      ...Callout
    }
    metaDescription
    metaImage {
      alt: description
      url
      width
      height
    }
    categories
    featuredImage {
      title
      url
      height
      width
    }
    body {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            title
            url
            height
            width
          }
        }
        entries {
          block {
            __typename
            sys { id }
            ...Accordion
            ...BuilderDevelopmentShort
            ...Callout
            ...Cta
            ...CommunityShort
            ...TopicRecord
            ...TopicPage
            ...PostBlogShort
            ...PostBlogCategory
            ...Grid
            ...Image
            ...TopicCarousel
            ...Snippet
            ...Video
          }
        }
      }
    }
    supportBody {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            title
            url
            height
            width
          }
        }
      }
    }
  }
`;

export const CONTENT_BLOCK_ATTRIBUTES = `
  fragment ContentBlock on ModuleContentBlock {
    __typename
    content {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            title
            url
            height
            width
          }
        }
      }
    }
    linkLabel
    linkUrl
    alignment
    textAlignment
  }
`;

// TODO - almost duplicate of RELATED_POSTS_QUERY - combine
export const MOST_RECENT_BLOGS_QUERY = `
query getMostRecentBlogs($limit: Int = 5) {
  postBlogCollection(limit: $limit, order: publishDate_DESC) {
    items {
      sys {
        id
      }
      title
      slug
      featuredImage {
        title
        url
        width
        height
      }
      publishDate
      label
    }
  }
}
`;

export const CAROUSEL_ATTRIBUTES = `
  fragment Carousel on Carousel {
    theme
    carouselHeader: header
    carouselDescription: description {
      json
    }
    slidesCollection (limit: 10) {
      cards: items {
        header
        author: name
        title
        description
        link
        linkLabel
        quote
        image {
          url
          title
          description
          width
          height
        }
      }
    }
  }
`;

export const CATEGORY_ATTRIBUTES = `
fragment Category on PostBlogCategory {
  __typename
  sys {
    id
  }
  slug
  categoryId
  title
  description
  headerImage {
    alt: title
    url
    height
    width
  }
  metaDescription
  metaImage {
    alt: description
    url
    width
    height
  }
}
`;

export const ALL_POSTS_QUERY = `
${POST_BLOG_SHORT_ATTRIBUTES}
query getRelatedArticles($category:[String], $searchFilter: String, $skip: Int!, $limit: Int!, $preview: Boolean!) {
  postBlogCollection (
    where: { title_contains: $searchFilter }
    skip: $skip, limit:$limit, order:publishDate_DESC, preview:$preview) {
    total
    items {
      ...PostBlogShort
    }
  }
  categoryCollection: postBlogCategoryCollection (where: { categoryId_in:$category}, preview:$preview) {
    items {
      slug
      title
    }
  }
}
`;

export const RELATED_POSTS_QUERY = `
${POST_BLOG_SHORT_ATTRIBUTES}
query getRelatedArticles($searchFilter:String, $category:[String], $skip: Int!, $limit: Int!, $slug: String, $preview:Boolean!) {
  postBlogCollection (
    where: {
      categories_contains_some:$category,
      slug_not:$slug,
      title_contains: $searchFilter
    },
    skip:$skip, limit:$limit, order:publishDate_DESC, preview:$preview) {
    total
    items {
      ...PostBlogShort
    }
  }
}`;

export const CATEGORY_INFO_QUERY = `
query getCategoryInfo($category:[String]) {
  categoryCollection: postBlogCategoryCollection (where: { categoryId_in:$category}) {
    items {
      slug
      title
      categoryId
    }
  }
}
`;

export const CATEGORY_LANDING_QUERY = `
${CATEGORY_ATTRIBUTES}
query GetBlogCategory($slug: String!, $skip: Int, $limit: Int) {
  categories: postBlogCategoryCollection(where: { slug: $slug }, limit: 1) {
    items {
      ...Category
    }
  }
}
`;

export const ACCORDION_ATTRIBUTES = `
  fragment Accordion on Accordion {
    __typename
    header
    listItems: listItemsCollection (limit: 10) {
      items{
        __typename
        ...on AccordionCollapsible {
          sys { id }
          label: title
          content: body {
            json
          }
        }
        ...on TopicRecord {
          sys { id }
          label: title
          body: excerpt
          linkLabel
          linkUrl
        }
        ...on TopicPage {
          sys { id }
          label: title
          body: excerpt
          topicPagePath: slug
          topicParentPath: topic
          linkLabel
        }
      }
    }
  }`;

export const COMMUNITY_ATTRIBUTES = `
  fragment Community on Community {
    __typename
    title
    slug
    excerpt
    serviceArea
    linkLabel
    introductionText,
    summaryText,
    transportationText,
    hospitalsText,
    shoppingText,
    recreationText,
    viewActivePropertiesLink
    metaDescription
    metaImage {
      alt: description
      url
      width
      height
    }
    featuredImage {
      alt: title
      url
      width
      height
    }
    headerImage {
      alt: title
      url
      width
      height
    }
    linkedFrom {
      lifestyleRecordCollection {
        items {
           ...LifestyleRecord
        }
      }
      eventRecordCollection {
        items {
          ...EventRecord
        }
      }
    }
  }
`;

export const SCHOOL_ATTRIBUTES = `
  fragment SchoolRecord on SchoolRecord {
    title: name
    suburb
    county
    state
    type
    phone
    linkLabel
    linkUrl
    excerpt
    location {
      lat
      lon
    }
    featuredImage {
      url
      width
      height
    }
  }
`;

// TODO - Look into merging these with the full fragments by using @include / @skip
export const COMMUNITY_SHORT_ATTRIBUTES = `
fragment CommunityShort on Community {
  __typename
  title
  serviceArea
  introductionText,
  excerpt: summaryText,
  featuredImage {
    alt: title
    url
    width
    height
  }
  slug
  label: linkLabel
}
`;

export const LIFESTYLE_ATTRIBUTES = `
  fragment LifestyleRecord on LifestyleRecord {
    sys { id }
    headline
    authorName
    linkLabel
    linkUrl
    expirationDate
    tags
    theme
    featuredImage {
      url
      width
      height
    }
  }
`;
export const EVENT_ATTRIBUTES = `
  fragment EventRecord on EventRecord {
    sys {
      id
    }
    headline
    authorName
    linkLabel
    linkUrl
    eventDate
    expirationDate
    schedule
    eventDetails
    featuredImage {
      url
      width
      height
    }
  }
`;

export const ALL_COMMUNITIES_QUERY = `
  ${COMMUNITY_SHORT_ATTRIBUTES}
  query getAllCommunities($preview: Boolean!) {
    communityCollection(order: [title_ASC], limit: 1000, preview:$preview) {
      total
      items {
        ...CommunityShort
      }
    }
  }
`;

export const SCHOOLS_QUERY = `
  ${SCHOOL_ATTRIBUTES}
  query GetSchools($skip: Int, $limit: Int, $where: SchoolRecordFilter) {
    schools: schoolRecordCollection(limit: $limit, skip: $skip, where: $where, order: [name_ASC]) {
      total
      items {
        ...SchoolRecord
      }
    }
  }
`;

export const CONTACT_FORM_ATTRIBUTES = `
  fragment ContactForm on BlockContactForm {
    __typename
    header
    formDescription: description {
      json
    }
    supportDescription {
      json
    }
    supportHeader
    endpoint
    mailTo
    subject
    linkLabel
    backgroundImage {
      src: url
      alt: title
      width
      height
    }
    supportImage {
      url
      alt: title
      width
      height
    }
  }
`;

export const BUILDER_DEVELOPMENT_SHORT_ATTRIBUTES = `
  fragment BuilderDevelopmentShort on PageBuilderdevelopment {
    __typename
    builderDevelopmentName
    category
    serviceArea
    excerpt,
    featuredImage {
      width
      height
      alt: title
      url
    }
    slug
    label: linkLabel
    nearbyCommunities: nearbyCommunitiesCollection (limit: 10) {
      items {
        ...CommunityShort
      }
    }
  }
`;

export const COMMUNITY_QUERY = `
${ACCORDION_ATTRIBUTES}
${COMMUNITY_ATTRIBUTES}
${CALLOUT_ATTRIBUTES}
${CONTACT_FORM_ATTRIBUTES}
${LIFESTYLE_ATTRIBUTES}
${EVENT_ATTRIBUTES}
${BUILDER_DEVELOPMENT_SHORT_ATTRIBUTES}
${COMMUNITY_SHORT_ATTRIBUTES}
query GetCommunity($slug: String, $preview: Boolean!) {
  datum: communityCollection(where: { slug: $slug }, preview: $preview limit: 1) {
    items {
      ...Community
      subHeading
      metro {
        sys { id }
        name
        accordion {
          ...Accordion
        }
        linkedFrom {
          lifestyleRecordCollection {
            items {
              ...LifestyleRecord
            }
          }
          eventRecordCollection {
            items {
              ...EventRecord
            }
          }
        }
      }
      contactForm {
        ...ContactForm
      }
      sidebar {
        ...Callout
      }
      nearbyCommunities: nearbyCommunitiesCollection (limit: 10) {
        items {
          ...Community
          ...BuilderDevelopmentShort
        }
      }
      schools: educationCollection (limit: 10) {
        items {
          description
          image {
            title
            url
            width
            height
          }
          link
        }
      }
    }
  }
}
`;

export const QUAD_ATTRIBUTES = `
  fragment Quad on ModuleQuad {
    title
    theme
      squares: tilesCollection (limit: 4) {
        items {
          title
          url
          label
          icon: image {
            title
            image {
              url
              width
              height
            }
          }
        }
      }
  }
`;

export const OFFICE_ATTRIBUTES = `
fragment Office on Office {
  __typename
  officeId
  name
  phone
  fax
  address
  geoLocationMapExternalLink
  city
  state
  zipCode
  photoUrl
  agents(order: { isPrimaryManager: DESC }) {
    __typename
    isManager
    isPrimaryManager
    isPrimaryOffice
    agent {
      websiteUrl
      photoUrl
      agentId
      professionalTitle
      designations
      name
      teamName
      email
      phone
      languages
      isOhioLicensed
      isKentuckyLicensed
      isIndianaLicensed
    }
  }
}
`;

export const OFFICE_PATHS_QUERY = `
query GetOfficePaths {
  officeCollection {
    items {
      slug
    }
  }
}`;

export const OFFICE_DETAIL_QUERY = `
${LIFESTYLE_ATTRIBUTES}
${EVENT_ATTRIBUTES}
${COMMUNITY_ATTRIBUTES}
query GetOfficeDetails($slug: String!, $preview:Boolean!) {
  officeCollection(where:{slug:$slug}, preview:$preview limit: 1) {
    items {
      __typename
      slug
      officeId
      title
      description: excerpt
      headerImage {
        alt: title
        url
        height
        width
      }
      metaDescription
      metaImage {
        alt: description
        url
        width
        height
      }
      nearbyCommunitiesCollection(limit:20) {
        items {
          __typename
          ...Community
        }
      }
    }
  }
}
`;

export const AGENT_FINDER_QUERY = gql`
  query AgentSearchQuery($searchName: String!, $language: String!, $take: Int!, $skip: Int!) {
    agentSearch(
      where: {
        and: [
          {
            or: [
              { name: { startsWith: $searchName } }
              { lastName: { startsWith: $searchName } }
              { nickname: { startsWith: $searchName } }
              { teamName: { startsWith: $searchName } }
            ]
          }
          { languages: { contains: $language } }
        ]
      }
      take: $take
      skip: $skip
      order: { lastName: ASC, agentId: ASC }
    ) {
      totalCount
      items {
        agentId
        name
        teamName
        languages
        designations
        professionalTitle
        websiteUrl
        photoUrl
        email
        phone
        isOhioLicensed
        isKentuckyLicensed
        isIndianaLicensed
      }
    }
  }
`;

export const AGENT_BY_ID_QUERY = `
query AgentSearchQuery($agentId: Int!) {
	agentSearch(
		where: { agentId: { eq: $agentId }}
	) {
    totalCount
		items {
			agentId
			name
      nickname
      lastName
      teamName
			languages
      designations
			professionalTitle
			websiteUrl
			photoUrl
			email
			phone
      officeId
      isOhioLicensed
      isKentuckyLicensed
      isIndianaLicensed
      displayPhotoUrl
      displayPrimary
			displaySecondary
			displayPhone
			displayEmail
			displayState
			displayBiography
			displayBusinessCardPhotoUrl
			socialMediaLinks {
				linkUrl
				description
			}
			testimonials {
				testimonialText
				signature
			}
      teamMembers {
        id
      }
		}
	}
}
`;

export const LISTINGS_BY_AGENT_ID_QUERY = `
  query GetAgentListings(
    $skip: Int!
    $take: Int!
    $sortBy: [ListingSortInput!]
    $agentId: Int!
    $statusId: [Byte]
  ) {
    listingSearch(
      where: {
        listingAgentId: { eq: $agentId }
        propertyTypeId: { neq: 3 }
        statusId: { in: $statusId, nin: [16] }
      }
      order: $sortBy
      skip: $skip
      take: $take
    ) {
      totalCount
      items {
        alertPills
        listingId
        listingUrl
        canonicalUrl
        bathroomCount
        bedroomCount

        daysSinceNew
        daysSinceLastPriceChange
        hasInRangeOpenHouse
        isNewConstruction
        fullBathroomCount
        partBathroomCount
        priceFormatted
        status
        acres
        address
        city
        state
        zip
        mainPhoto {
          midSizeImageUrl
        }
        mlsCopyright {
          logoUrl
          description
        }
        contact {
          primaryContactName
          secondaryContactName
        }
        agents {
          isActiveSibcyAgent
        }
      }
    }
  }
`;

export const LISTINGS_BY_AGENT_ID_PLUS_TEAM_QUERY = `
  query GetAgentListings(
    $skip: Int!
    $take: Int!
    $sortBy: [ListingSortInput!]
    $teamMembers: [Int!]
    $statusId: [Byte]
  ) {
    listingSearch(
      where: {
        propertyTypeId: { neq: 3 }
        statusId: { in: $statusId, nin: [16] }
        or: [{ listingAgentId: { in: $teamMembers } }, { listingCoAgentId: { in: $teamMembers } }]
      }
      order: $sortBy
      skip: $skip
      take: $take
    ) {
      totalCount
      items {
        alertPills
        listingId
        listingUrl
        canonicalUrl
        bathroomCount
        bedroomCount
        daysSinceNew
        daysSinceLastPriceChange
        hasInRangeOpenHouse
        isNewConstruction
        fullBathroomCount
        partBathroomCount
        priceFormatted
        status
        acres
        address
        city
        state
        zip
        mainPhoto {
          midSizeImageUrl
        }
        mlsCopyright {
          logoUrl
          description
        }
        contact {
          primaryContactName
          secondaryContactName
        }
        agents {
          isActiveSibcyAgent
        }
      }
    }
  }
`;

export const NEW_OFFICE_LISTINGS = gql`
  query GetOfficeListings($officeId: Int!, $take: Int!) {
    listingSearch(
      where: {
        listingOfficeId: { eq: $officeId }
        statusId: { neq: 14 }
        propertyTypeId: { neq: 3 }
      }
      order: { onMarketDate: DESC }
      take: $take
    ) {
      totalCount
      items {
        listingId
        listingUrl
        canonicalUrl
        bathroomCount
        bedroomCount
        daysSinceNew
        daysSinceLastPriceChange
        hasInRangeOpenHouse
        isNewConstruction
        fullBathroomCount
        partBathroomCount
        priceFormatted
        status
        acres
        address
        city
        state
        zip
        mainPhoto {
          midSizeImageUrl
        }
        contact {
          primaryContactName
          secondaryContactName
        }
        agents {
          isActiveSibcyAgent
        }
      }
    }
  }
`;

export const TOPIC_PAGE_QUERY = `
    ${ACCORDION_ATTRIBUTES}
    ${GRID_ATTRIBUTES}
    ${SNIPPET_ATTRIBUTES}
    ${IMAGE_ATTRIBUTES}
    ${TOPIC_CAROUSEL_ATTRIBUTES}
    ${TOPIC_RECORD_ATTRIBUTES}
    ${TOPIC_PAGE_ATTRIBUTES}
    ${CALLOUT_ATTRIBUTES}
    ${AGENT_FINDER_ATTRIBUTES}
    ${COMMUNITY_SHORT_ATTRIBUTES}
    ${POST_BLOG_SHORT_ATTRIBUTES}
    ${POST_BLOG_CATEGORY_ATTRIBUTES}
    ${CTA_ATTRIBUTES}
    ${FEATURED_LISTINGS_BLOCK_ATTRIBUTES}
    query GetTopicPageDetails($topic: String!, $slug: String!, $preview: Boolean!) {
    topicPageCollection(where:{topic:$topic, slug:$slug}, preview:$preview limit:1) {
      items{
        slug
        title
        topic
        headerImage {
          url
          alt: description
          width
          height
        }
        headerCtAsCollection {
          items {
            ...Cta
          }
        }
        excerpt
        featuredImage {
          url
          alt: description
          width
          height
        }
        metaTitle
        metaDescription
        metaImage {
          alt: description
          url
          width
          height
        }
        body {
          json
          links {
            entries {
              block {
                __typename
                sys { id }
                ...Accordion
                ...Callout
                ...TopicRecord
                ...TopicPage
                ...CommunityShort
                ...PostBlogShort
                ...PostBlogCategory
                ...Grid
                ...Image
                ...TopicCarousel
                ...Snippet
                ...AgentFinder
                ...BlockFeaturedListings
              }
            }
            assets {
              block {
                sys {
                  id
                }
                title
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }`;

export const POST_QUERY = `
  ${ACCORDION_ATTRIBUTES}
  ${BUILDER_DEVELOPMENT_SHORT_ATTRIBUTES}
  ${CALLOUT_ATTRIBUTES}
  ${COMMUNITY_SHORT_ATTRIBUTES}
  ${CTA_ATTRIBUTES}
  ${GRID_ATTRIBUTES}
  ${IMAGE_ATTRIBUTES}
  ${POST_BLOG_ATTRIBUTES}
  ${POST_BLOG_SHORT_ATTRIBUTES}
  ${POST_BLOG_CATEGORY_ATTRIBUTES}
  ${SNIPPET_ATTRIBUTES}
  ${TOPIC_CAROUSEL_ATTRIBUTES}
  ${TOPIC_RECORD_ATTRIBUTES}
  ${TOPIC_PAGE_ATTRIBUTES}
  ${VIDEO_ATTRIBUTES}
  query GetBlogPost($slug: String!, $preview: Boolean!) {
    postBlogCollection(where: { slug: $slug }, preview: $preview limit: 1) {
      items {
        ...PostBlog
      }
    }
  }
`;

export const COMING_SOON_QUERY = gql`
  query ComingSoonQuery($skip: Int!, $take: Int!, $sortBy: [ListingSortInput!]) {
    listingSearch(where: { statusId: { eq: 14 } }, order: $sortBy, skip: $skip, take: $take) {
      totalCount
      items {
        alerts {
          text
          type
        }
        acres
        address
        agents {
          isActiveSibcyAgent
        }
        alertPills
        bathroomCount
        fullBathroomCount
        bedroomCount
        canonicalUrl
        city
        contact {
          primaryContactName
          secondaryContactName
        }
        daysSinceNew
        daysSinceLastPriceChange
        hasInRangeOpenHouse
        isNewConstruction
        onMarketDate
        listingId
        listingUrl
        partBathroomCount
        mainPhoto {
          midSizeImageUrl
        }
        mlsCopyright {
          logoUrl
          description
        }
        price
        priceFormatted
        schoolDistrict
        state
        status
        streetName
        suburb
        zip
      }
    }
  }
`;

export const OFFICE_LISTINGS_QUERY = gql`
  query GetOfficeListings($skip: Int!, $take: Int!, $sortBy: [ListingSortInput!], $officeId: Int!) {
    listingSearch(
      where: {
        listingOfficeId: { eq: $officeId }
        statusId: { nin: [6, 14] }
        propertyTypeId: { neq: 3 }
      }
      order: $sortBy
      skip: $skip
      take: $take
    ) {
      totalCount
      items {
        alertPills
        listingId
        listingUrl
        canonicalUrl
        bathroomCount
        bedroomCount
        daysSinceNew
        daysSinceLastPriceChange
        hasInRangeOpenHouse
        isNewConstruction
        fullBathroomCount
        partBathroomCount
        priceFormatted
        status
        acres
        address
        city
        state
        zip
        mainPhoto {
          midSizeImageUrl
        }
        mlsCopyright {
          logoUrl
          description
        }
        contact {
          primaryContactName
          secondaryContactName
        }
        agents {
          isActiveSibcyAgent
        }
      }
    }
  }
`;

const RICH_TEXT_ATTRIBUTES = `
  json
  links {
    entries {
      block {
        __typename
        sys {
          id
        }
        ...Accordion
        ...Callout
        ...Cta
        ...TopicRecord
        ...TopicPage
        ...BuilderDevelopmentShort
        ...CommunityShort
        ...PostBlogShort
        ...PostBlogCategory
        ...Grid
        ...Image
        ...TopicCarousel
        ...Snippet
        ...Video
      }
    }
    assets {
      block {
        sys {
          id
        }
        title
        url
        width
        height
      }
    }
  }
`;

export const BUILDER_DEVELOPMENT_PAGE_QUERY = `
  ${ACCORDION_ATTRIBUTES}
  ${BUILDER_DEVELOPMENT_SHORT_ATTRIBUTES}
  ${CALLOUT_ATTRIBUTES}
  ${COMMUNITY_SHORT_ATTRIBUTES}
  ${CONTACT_FORM_ATTRIBUTES}
  ${CTA_ATTRIBUTES}
  ${GRID_ATTRIBUTES}
  ${IMAGE_ATTRIBUTES}
  ${POST_BLOG_CATEGORY_ATTRIBUTES}
  ${POST_BLOG_SHORT_ATTRIBUTES}
  ${TOPIC_CAROUSEL_ATTRIBUTES}
  ${TOPIC_RECORD_ATTRIBUTES}
  ${TOPIC_PAGE_ATTRIBUTES}
  ${SNIPPET_ATTRIBUTES}
  ${VIDEO_ATTRIBUTES}
  query GetBuilderDevelopmentPages($category: String!, $slug: String!, $preview: Boolean!) {
    pageBuilderdevelopmentCollection(
      where: { category: $category, slug: $slug }
      preview: $preview
      limit: 1
    ) {
      items {
        slug
        excerpt
        serviceArea
        linkLabel
        primaryLink
        allAgentPermission
        agentid
        category
        builderDevelopmentName
        coordinates {
          lat
          lon
        }
        metaDescription
        metaImage {
          alt: description
          url
        }
        featuredImage {
          alt: title
          url
          width
          height
        }
        headerImage {
          alt: title
          url
          width
          height
        }
        body {
          ${RICH_TEXT_ATTRIBUTES}
        }
        featuresText {
          ${RICH_TEXT_ATTRIBUTES}
        }
        developmentPlat {
          ${RICH_TEXT_ATTRIBUTES}
        }
        floorplans {
          ${RICH_TEXT_ATTRIBUTES}
        }
        elevations {
          ${RICH_TEXT_ATTRIBUTES}
        }
        schoolInformation {
          ${RICH_TEXT_ATTRIBUTES}
        }
        photoRichText: photos {
          ${RICH_TEXT_ATTRIBUTES}
        }
        photos: photosAssetsCollection {
          items {
            title
            height
            width
            url
          }
        }
        nearbyCommunities: nearbyCommunitiesCollection (limit: 10 where: { sys: { publishedAt_exists: true } }) {
          items {
            ...CommunityShort
            ...BuilderDevelopmentShort
          }
        }
        contactForm {
          ...ContactForm
        }
        metro {
          sys { id }
          name
          accordion {
            ...Accordion
          }
        }
      }
    }
  }
`;

export const ALL_BUILDER_DEVELOPMENT_QUERY = `
  ${BUILDER_DEVELOPMENT_SHORT_ATTRIBUTES}
  ${COMMUNITY_SHORT_ATTRIBUTES}
  query GetBuilderDevelopmentPages($category: String!) {
    pageBuilderdevelopmentCollection(where: { category: $category} limit: 100) {
      items {
        slug
        excerpt
        serviceArea
        linkLabel
        primaryLink
        category
        builderDevelopmentName
        featuredImage {
          alt: title
          url
          width
          height
        }
        nearbyCommunities: nearbyCommunitiesCollection (limit: 4) {
          items {
            ...CommunityShort
            ...BuilderDevelopmentShort
          }
        }
      }
    }
  }
`;

export const LISTINGS_BY_DEVELOPMENT_QUERY = gql`
  query ListingsQuery($developmentSlug: String, $statusId: Byte, $take: Int!, $skip: Int!) {
    listingSearch(
      where: {
        and: [{ developmentSlug: { eq: $developmentSlug } }, { statusId: { eq: $statusId } }]
      }
      take: $take
      skip: $skip
      order: [{ price: DESC }, { address: ASC }]
    ) {
      totalCount
      items {
        listingId
        city
        state
        zip
        address
        listingId
        listingUrl
        mainPhoto {
          midSizeImageUrl
        }
        photos {
          midSizeImageUrl
        }
        price
        priceFormatted
        streetName
        suburb
      }
    }
  }
`;

export const LISTINGS_BY_BUILDER_QUERY = gql`
  query ListingsQuery($builderSlug: String, $statusId: Byte, $take: Int!, $skip: Int!) {
    listingSearch(
      where: { and: [{ builderSlug: { eq: $builderSlug } }, { statusId: { eq: $statusId } }] }
      take: $take
      skip: $skip
      order: [{ price: DESC }, { address: ASC }]
    ) {
      totalCount
      items {
        listingId
        city
        state
        zip
        address
        listingId
        listingUrl
        mainPhoto {
          midSizeImageUrl
        }
        photos {
          midSizeImageUrl
        }
        price
        priceFormatted
        streetName
        suburb
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
export const buildAgentFinderQuery = ({ searchName, language, states, take, skip }) => {
  // Construct state filters based on selected states
  let stateFilters = states.map((state) => `{ ${state}: { eq: true } }`).join(', ');

  return gql`
    query AgentSearchQuery($searchName: String!, $language: String!, $take: Int!, $skip: Int!) {
      agentSearch(
        where: {
          and: [
            {
              or: [
                { name: { startsWith: $searchName } }
                { lastName: { startsWith: $searchName } }
                { nickname: { startsWith: $searchName } }
                { teamName: { startsWith: $searchName } }
              ]
            }
            { languages: { contains: $language } }
            ${stateFilters.length > 0 ? `{ and: [${stateFilters}] }` : ''}
          ]
        }
        take: $take
        skip: $skip
        order: { lastName: ASC, agentId: ASC }
      ) {
        totalCount
        items {
          isOhioLicensed
          isKentuckyLicensed
          isIndianaLicensed
          agentId
          name
          teamName
          languages
          designations
          professionalTitle
          websiteUrl
          photoUrl
          email
          phone
        }
      }
    }
  `;
};
