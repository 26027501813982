/* eslint-disable no-empty */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import Toast from '@components/toast';
import axios from 'axios';

const initialState = () => {
  return { saved: [], userInfo: {}, authenticated: false, loading: false, error: null };
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload.authenticated;
      state.userInfo = action.payload.userInfo;
    },
    setAssociatedAgent: (state, action) => {
      state.userInfo.agent = action.payload.agent;
    },
    setAllSavedListings: (state, action) => {
      const { error, loading, listings } = action.payload;

      state.loading = loading;
      state.error = error;
      state.saved = listings;
    },
    setSavedListing: (state, action) => {
      const savedListingIndex = state.saved.findIndex((el) => {
        return el.savedListingId === action.payload.savedListing.savedListingId;
      });
      const newSavedListings = [...state.saved];

      if (savedListingIndex !== -1 && action.payload.shouldRemove) {
        newSavedListings.splice(savedListingIndex, 1);
      } else if (savedListingIndex !== -1) {
        newSavedListings[savedListingIndex] = action.payload.savedListing;
      } else {
        newSavedListings.unshift(action.payload.savedListing);
      }
      state.saved = newSavedListings;
    },
    setFetchStatus: (state, action) => {
      state.loading = action.payload.loading;
      state.error = action.payload.error;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (!action.payload.user) {
        return state;
      }
      state = action.payload.user;
    }
  }
});

export const { setSaved, setAuthenticated } = userSlice.actions;

export const fetchSavedListings = async (dispatch) => {
  try {
    dispatch({ type: 'user/setFetchStatus', payload: { loading: true, error: false } });
    const {
      data: { saved }
    } = await axios.get('/api/user/saved');
    const savedSorted = saved.sort((a, b) => b.lastSavedOn - a.lastSavedOn);
    dispatch({
      type: 'user/setAllSavedListings',
      payload: { listings: savedSorted, loading: false, error: false }
    });
  } catch (ex) {
    dispatch({ type: 'user/setFetchStatus', payload: { loading: false, error: true } });
    console.error(ex);
  }
};

export const updateAssociatedAgent = (associatedAgent) => {
  return {
    type: 'user/setAssociatedAgent',
    payload: { agent: associatedAgent }
  };
};

export const fetchUser = async (dispatch) => {
  try {
    const response = await axios.get('/api/auth/me');
    const {
      data: { associatedAgent }
    } = await axios.get('/api/user/account');

    const userInfo = { ...response?.data, agent: associatedAgent };
    dispatch({
      type: 'user/setAuthenticated',
      payload: { authenticated: response.status === 200, userInfo }
    });
  } catch (ex) {
    console.error(ex);
  }
};

export const updateSavedListing = (opts) => async (dispatch) => {
  try {
    // Update saved listing record
    const data = await axios.put(`/api/user/saved`, opts);
    if (data.errors) throw new Error('error fetching saved listing data', data.errors);
    const savedListing = { ...opts, ...data?.data };
    const shouldRemove =
      !savedListing.isFavorited &&
      !savedListing.shouldNotifyOnOpenHouseScheduled &&
      !savedListing.shouldNotifyOnPriceChange &&
      !savedListing.shouldNotifyOnStatusChange &&
      savedListing.noteCount == 0;

    dispatch({
      type: 'user/setSavedListing',
      payload: {
        savedListing,
        shouldRemove
      }
    });

    Toast.notify({
      message: `${!shouldRemove ? 'S' : 'Removed s'}aved listing successfully`,
      type: 'success'
    });
  } catch (ex) {
    console.error(ex);
    fetchSavedListings(dispatch);
  }
};

export default userSlice.reducer;
