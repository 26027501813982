export default (input) => {
  let layout = input?.layout?.toLowerCase();

  if (input?.gridOptions?.layout === 'single') {
    layout = 'single';
  }

  // -------------------------------------------------------

  return {
    ...input,
    backgroundImage: input?.backgroundImage?.image || input?.backgroundImage,
    gridOptions: {
      gutter: input.gutter?.toString(),
      layout: layout,
      verticalAlignment: input.verticalAlignment
    },
    textAlignment: input?.textAlignment?.toLowerCase(),
    theme: input.theme
  };
};
