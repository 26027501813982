import { additional, bedAndBath, price, statusAndType } from './filters';

export default {
  error_state: 'Something went wrong with your search. Please try again.',
  filters: [statusAndType, price, bedAndBath, additional],
  heading: 'Search Results',
  sortButtonOptions: [
    {
      label: 'Highest Price',
      value: [{ price: 'DESC' }, { address: 'ASC' }]
    },
    {
      label: 'Lowest Price',
      value: [{ price: 'ASC' }, { address: 'ASC' }]
    },
    {
      label: 'Newest Listings',
      value: [{ daysSinceNew: 'ASC' }, { price: 'DESC' }, { address: 'ASC' }]
    },
    {
      label: 'Open House Date',
      value: [{ hasInRangeOpenHouse: 'DESC' }, { price: 'DESC' }, { address: 'ASC' }]
    },
    {
      label: 'Price Change',
      value: [{ priceLastChangedOn: 'DESC' }, { price: 'DESC' }, { address: 'ASC' }]
    }
  ],
  zero_state: 'No properties matched your search criteria. Adjust the filters above or start a ',
  zero_state_address:
    'No properties match the partial street search and filter combination you provided. Adjust the filters above or start a ',
  zero_state_link: 'new search'
};
