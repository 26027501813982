// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Container = (props) => <Component {...transform(props)} />;

export default Container;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
