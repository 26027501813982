// ---------------------------------------------------------

import Component, { layoutOptions } from './component';
import transform from './transformer';

// ---------------------------------------------------------

const Image = (props) => <Component {...transform(props)} />;

export default Image;

// ---------------------------------------------------------

export { Component as component, layoutOptions, transform as transformer };
