const image = { height: 579, src: '/storybook/card-property-image@3x.jpg', width: 870 };

// ---------------------------------------------------------

const realtor = {
  isActiveSibcyAgent: true,
  primaryRealtorName: 'Ruby Jameson'
};

// ---------------------------------------------------------

const alertPills = ['Coming Soon', 'Open House'];

const propertyDetails = {
  address: '1059 Celestial St',
  alertPills: alertPills,
  city: 'Mt. Adams',
  details: [{ br: 4 }, { ba: '3/1' }, { ac: 0.24 }],
  price: '$204,000',
  realtor: realtor,
  state: 'OH',
  zip: '45202'
};

const cardDefaults = {
  image: image,
  property: {
    ...propertyDetails
  },
  url: '/'
};

// ---------------------------------------------------------

export default {
  props: {
    ...cardDefaults,
    property: {
      ...propertyDetails
    }
  },
  themeMedia: {
    ...cardDefaults,
    theme: 'media'
  },
  withPrimaryAndSecondaryRealtors: {
    ...cardDefaults,
    property: {
      ...propertyDetails,
      realtor: {
        ...realtor,
        secondaryRealtorName: 'Elizabeth Johnson'
      }
    }
  },
  withPrimaryRealtor: {
    ...cardDefaults
  },
  withoutRealtor: {
    ...cardDefaults,
    property: {
      ...propertyDetails,
      realtor: {}
    }
  }
};
