import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getSearchResults } from '@lib/search-results';
import { convertUrlToQueryObject } from '@lib/query-utils';
import { getSlug } from '@lib/utils';

// ---------------------------------------------------------

import { header_container, see_all_link, loading_container } from './styles.module.scss';

import Link from '@components/link';
import Container from '@layout/container';
import Grid from '@layout/grid';
import PropertyCard from '@components/card';
import TopicCarousel from '@components/topic-carousel';
import Icon from '@components/icon';
import Spinner from '@components/spinner';

// ---------------------------------------------------------

const FeaturedListings = ({ header, seeAllLink, resultsPageUrl, theme, limit }) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nolistings, setNoListings] = useState(false);

  useEffect(() => {
    const fetchListings = async () => {
      const results = await getSearchResults(
        convertUrlToQueryObject(resultsPageUrl),
        'resultsPage',
        limit
      );

      setListings(results?.data?.listingSearch?.items);
      if (results?.data?.listingSearch?.items) {
        setLoading(false);
      }

      if (results?.data?.listingSearch?.items.length === 0) {
        setNoListings(true);
      }
    };

    fetchListings();
  }, []);

  const listingCards = listings.map((listing) => {
    return (
      <PropertyCard
        alertPills={listing.alertPills}
        image={{
          src: !listing.mainPhoto
            ? 'https://place-hold.it/340x231/green/white&text=FPO&fontsize=16'
            : `${listing.mainPhoto.midSizeImageUrl}`
        }}
        property={{
          ...listing,
          details: [
            { br: listing.bedroomCount },
            {
              ba: listing.partBathroomCount
                ? `${listing.fullBathroomCount}/${listing.partBathroomCount}`
                : listing.fullBathroomCount
            },
            { ac: listing.acres }
          ],
          price: listing.priceFormatted,
          realtor: {
            isActiveSibcyAgent: listing.agents[0]?.isActiveSibcyAgent,
            primaryRealtorName: listing.contact.primaryContactName,
            secondaryRealtorName: listing.contact.secondaryContactName
          }
        }}
        url={getSlug(listing.canonicalUrl)}
        key={`card-${listing.listingId}`}
      />
    );
  });

  let content;
  if (listings.length > 0) {
    let condition;
    if (theme === 'Carousel' && listings.length <= 3) {
      condition = 'default';
    } else {
      condition = theme;
    }

    switch (condition) {
      case 'Carousel':
        content = <TopicCarousel slidesCollection={{ cards: listings }} theme="listings" />;
        break;
      case 'Grid':
        content = <Grid layout="third">{listingCards}</Grid>;
        break;
      default:
        content = <Grid layout="third">{listingCards}</Grid>;
    }
  }

  if (!nolistings) {
    return (
      <Container layout="single">
        <div className={header_container}>
          <h2>{header}</h2>
          {seeAllLink && (
            <Link className={see_all_link} url={resultsPageUrl}>
              See all <Icon type="arrow" name={'small-right'} />
            </Link>
          )}
        </div>

        {loading && (
          <div className={loading_container}>
            <Spinner />
          </div>
        )}
        {!loading && <>{content}</>}
      </Container>
    );
  }
};

FeaturedListings.propTypes = {
  header: PropTypes.string,
  seeAllLink: PropTypes.bool,
  resultsPageUrl: PropTypes.string,
  theme: PropTypes.oneOf(['Carousel']),
  limit: PropTypes.number
};

FeaturedListings.defaultProps = {
  seeAllLink: false
};

export default FeaturedListings;
