const transformed = (input) => {
  const parseTheme = (theme) => {
    return theme?.split(' ').join('-').toLowerCase() || theme;
  };

  return {
    ...input,
    theme: parseTheme(input?.theme)
  };
};

export default transformed;
