import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';
import Content from '@components/content';

// ---------------------------------------------------------

import {
  text,
  text_link,
  alignment_bottom,
  alignment_center,
  alignment_top,
  text_align_center,
  text_align_right,
  text_align_left,
  spacing_bottom_none,
  wrapper
} from './styles.module.scss';

const alignmentOptions = {
  bottom: alignment_bottom,
  center: alignment_center,
  none: null,
  top: alignment_top
};

const textAlignmentOptions = {
  center: text_align_center,
  left: text_align_left,
  right: text_align_right
};

const bottomSpacingOptions = {
  none: spacing_bottom_none,
  normal: null
};

// ---------------------------------------------------------

const TextBlock = (props) => {
  const { content, title, link, subtitle, alignment, textAlignment, bottomSpacing } = props;
  // ---------------------------------------------------------

  const classes = classNames(text, {
    [alignmentOptions[alignment]]: alignmentOptions[alignment],
    [textAlignmentOptions[textAlignment]]: textAlignmentOptions[textAlignment],
    [bottomSpacingOptions[bottomSpacing]]: bottomSpacingOptions[bottomSpacing]
  });

  // ---------------------------------------------------------

  return (
    <div className={wrapper}>
      <div className={classes}>
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}
        {typeof content === 'string' ? <p>{content}</p> : <Content content={content} />}
        {link?.label && link?.url && (
          <Link aria-label={link?.aria} className={text_link} url={link?.url}>
            {link?.label} <Icon type="arrow" name={'small-right'} />
          </Link>
        )}
      </div>
    </div>
  );
};

TextBlock.propTypes = {
  /**
   * Specifies how the content should be aligned within the block
   */
  alignment: PropTypes.oneOf(['top', 'bottom', 'center']),

  /**
   * determines if there should be spacing below the content block or not
   */
  bottomSpacing: PropTypes.string,

  /**
   * this should likely be turend into a wysiwyg block and use npm @contentful/rich-text-react-renderer
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  link: PropTypes.shape({
    aria: PropTypes.string,
    icon: PropTypes.bool,
    label: PropTypes.string,
    url: PropTypes.string
  }),

  subtitle: PropTypes.string,
  /**
   * Determines if the alignment of the text horizontally.
   */
  textAlignment: PropTypes.string,
  title: PropTypes.string
};

TextBlock.defaultProps = {
  alignment: 'center',
  bottomSpacing: 'none',
  textAlignment: 'left'
};

export default TextBlock;
