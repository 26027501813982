import { useEffect } from 'react';

// ---------------------------------------------------------

import Container from '@layout/container';
import Layout from '@layout/index';

// ---------------------------------------------------------

const FiveHundredPage = () => {
  useEffect(() => {
    if (window.Rollbar && process.env.NEXT_PUBLIC_NOTIFY_ROLLBAR_ON_500) {
      window.Rollbar.warning('500 Server Error');
    }
  }, []);

  return (
    <Layout>
      <Container spacing="large" gridOptions={{ layout: 'single' }}>
        <h1>Server Error</h1>
        <p>Uh oh! The server ran into an error while grabbing your data.</p>
      </Container>
    </Layout>
  );
};

export default FiveHundredPage;
