import Search from '@lib/models/search';
import { fixtures } from '@content/results';
import { coerceToArray, convertFromReduxParams } from './query-utils';
import {
  heatingTypeMap,
  paramsShouldBeArray,
  paramsShouldBeInt,
  paramsShouldBeBool,
  basementTypeMap,
  propertyStatusMap,
  mustHaveMap
} from './constants';

const PAGESIZE = 15;

/**
 * Fetches search results asynchronously based on provided parameters and query type.
 * @param {Object} queryParams - The query parameters.
 * @param {string} queryType - The type of query to perform.
 * @returns {Promise<Object>} - The search result data.
 */
export const getSearchResults = async (queryParams, queryType, limit) => {
  const search = new Search();
  const queryParamObject =
    queryType === 'ogMetaResults' ? removeAgentIdParams(queryParams) : queryParams;

  const { convertedRouterParams, daysSinceNew, daysSinceLastPriceChange } =
    convertQueryStringToRedux(queryParamObject);

  const { paramsForGraphQL } = await convertFromReduxParams(
    convertedRouterParams,
    daysSinceNew,
    daysSinceLastPriceChange
  );

  const sortItem =
    fixtures.sortButtonOptions.find((item) => item.label === convertedRouterParams.sortby) ||
    fixtures.sortButtonOptions[0];

  const variables = {
    params: paramsForGraphQL,
    queryType,
    skip: ((convertedRouterParams.page || 1) - 1) * PAGESIZE,
    sortBy: sortItem.value,
    take: limit || PAGESIZE
  };

  try {
    const data = await search.perform(variables).catch((error) => console.error(error.message));
    if (queryType !== 'ogMetaResults') {
      const graphQLString = search.saveSearchString({ ...variables, params: paramsForGraphQL });
      return { data, sortItem, convertedRouterParams, graphQLString };
    }
    return data;
  } catch (error) {
    console.error(error);
    return { error: 'An error occurred while fetching data' };
  }
};

/**
 * Removes 'agentid' parameter from search parameters and handles conversion of values.
 * @param {URLSearchParams} queryParams - The search parameters.
 * @returns {Object} - The cleaned and processed query parameters.
 */
const removeAgentIdParams = (queryParams) => {
  const queryParamObject = {};
  for (const [key, value] of queryParams) {
    if (key === 'agentid') continue;
    const values = queryParams.getAll(key);
    queryParamObject[key] =
      values.length > 1 ? values : value === 'true' ? true : value === 'false' ? false : value;
  }
  return queryParamObject;
};

/**
 * Converts query string parameters into a Redux-compatible format, applying transformations as necessary.
 * @param {Object} inputParams - The input parameters.
 * @returns {Object} - The converted parameters suitable for Redux.
 */
const convertQueryStringToRedux = (inputParams) => {
  const newParams = { ...inputParams };
  let daysSinceLastPriceChange = '';
  let daysSinceNew = '';

  // Directly reference the maps and associate them with their actions
  const actionMap = [
    { map: heatingTypeMap, propName: 'heatingTypes' },
    { map: basementTypeMap, propName: 'basementTypes' },
    { map: propertyStatusMap, propName: 'propertyStatus' },
    { map: mustHaveMap, propName: 'mustHaves' }
  ];

  // Iterate over newParams' keys for parameter adjustment.
  for (const key in newParams) {
    // Convert specified keys to arrays for consistent handling.
    if (paramsShouldBeArray.includes(key)) newParams[key] = coerceToArray(newParams[key]);

    // Parse integer values where needed, remove if parsing fails or value is falsy.
    if (paramsShouldBeInt.includes(key)) {
      const parsedValue = parseInt(newParams[key]);
      if (!parsedValue) delete newParams[key];
      else newParams[key] = parsedValue;
    }

    // Convert 'true' strings or boolean true to true, ensures boolean type.
    if (paramsShouldBeBool.includes(key))
      newParams[key] = newParams[key] === 'true' || newParams[key] === true;

    // Set default propertyTypeId if unspecified.
    if (!newParams.propertyTypeId) newParams.propertyTypeId = 0;

    // manually add in daysSinceLastPriceChange and daysSinceNew
    if (key === 'daysSinceLastPriceChange') daysSinceLastPriceChange = newParams[key];
    if (key === 'daysSinceNew') daysSinceNew = newParams[key];

    actionMap.forEach(({ map, propName }) => {
      if (map.includes(key)) {
        // Ensure the property array exists and append the current key
        newParams[propName] = [...(newParams[propName] || []), key];
        // Remove the original key
        delete newParams[key];
      }
    });
  }

  const decodeArr = (arr, areaType) =>
    arr
      ? [].concat(arr).map((loc) => {
          const [location, state] = loc.split('_');
          return { label: `${location}, ${state}`, value: { areaType, location, state } };
        })
      : [];

  const locations = [
    ...decodeArr(inputParams?.counties, 'counties'),
    ...decodeArr(inputParams?.places, 'places'),
    ...decodeArr(inputParams?.schoolDistricts, 'schoolDistricts'),
    ...decodeArr(inputParams?.zip, 'zip')
  ];

  delete newParams.counties;
  delete newParams.places;
  delete newParams.schoolDistricts;
  delete newParams.zip;

  return {
    convertedRouterParams: { ...newParams, locations },
    daysSinceNew,
    daysSinceLastPriceChange
  };
};
