/* eslint-disable no-undef */
import { useEffect } from 'react';
import Script from 'next/script';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

import { buyside_form, theme_unifed_search } from './styles.module.scss';

// ---------------------------------------------------------

const BuysideWidget = ({ theme }) => {
  const widgetId =
    theme === 'unified search'
      ? 'buyside-widget-container-unified-search'
      : 'buyside-widget-container';

  const btnValue =
    theme === 'unified search'
      ? `
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="arrow_forward_FILL0_wght400_GRAD0_opsz48" fill="#000000" fill-rule="nonzero">
                  <polygon id="Path" points="8 16 6.95 14.925 13.125 8.75 0 8.75 0 7.25 13.125 7.25 6.95 1.075 8 0 16 8"></polygon>
              </g>
          </g>
      </svg>
      `
      : 'Get Report';

  useEffect(() => {
    delete window.buyside_widget_settings?.containerId;
    delete window.buyside_widget_settings?.inputId;
    delete window.buyside_widget_settings?.buttonId;

    const widgetInterval = setInterval(() => {
      if (window.buyside_widget !== undefined) {
        clearInterval(widgetInterval);

        buyside_widget({
          inputPlaceHolder:
            theme === 'unified search'
              ? 'Enter an address for a free market report'
              : 'Enter your address',
          key: '1580535649009208',
          openNewWindow: true,
          submitBtnValue: btnValue,
          widgetContainerId: widgetId
        });
      }
    }, 100);

    return () => {
      clearInterval(widgetInterval);
    };
  }, []);

  // ---------------------------------------------------------

  return (
    <>
      <Script src="//api.buyermls.com/widget/buyside-widget-v31.js" id="buyside-script" />
      <div
        id={widgetId}
        className={classNames(buyside_form, {
          [theme_unifed_search]: theme === 'unified search'
        })}
      ></div>
    </>
  );
};

BuysideWidget.propTypes = {
  theme: PropTypes.string
};

BuysideWidget.defaultProps = {};

export default BuysideWidget;
