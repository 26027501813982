import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import Content from '@components/content';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';

// ---------------------------------------------------------

import {
  title,
  accordion_item,
  content_container,
  icon,
  label_container,
  theme_red,
  topic_record_link,
  heading
} from './styles.module.scss';

const themeOptions = {
  none: null,
  red: theme_red
};

// ---------------------------------------------------------

const AccordionComponent = (props) => {
  let {
    header,
    listItems: { items },
    theme,
    openAccordionId
  } = props;

  const classes = classNames(accordion_item, {
    [themeOptions[theme]]: themeOptions[theme]
  });

  return (
    // If Accordion is Open on Mount the preExpanded id will match the Accordion Item uuid
    <Accordion allowZeroExpanded={true} preExpanded={openAccordionId}>
      {header && <h2 className={title}>{header}</h2>}
      {items?.map((item, idx) => {
        const isContentful = item.content?.json;

        return (
          <AccordionItem
            className={classes}
            key={`nearby-community-${item.id || idx}`}
            uuid={item.id}
          >
            <AccordionItemHeading>
              <AccordionItemButton className={label_container}>
                <h2 className={heading}>{item.label}</h2>
                <div className={icon}>
                  <Icon type="arrow" name="default-down" />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={content_container}>
              {isContentful ? <Content content={item?.content} /> : item.content?.value}
              {item?.body}
              {item.linkUrl && (
                <Link
                  url={item.linkUrl}
                  ariaLabel={item.linkLabel || 'Learn more'}
                  className={topic_record_link}
                >
                  {item.linkLabel || 'Learn more'}
                </Link>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  header: PropTypes.string,
  listItems: PropTypes.shape({
    /**
     * Specifies accordion items
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Specifies the body (This field is only for Topic Records and used for community page)
         */
        body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        /**
         * Specifies the content for the accordion
         */
        content: PropTypes.oneOfType([
          PropTypes.shape({
            value: PropTypes.node
          }),
          PropTypes.shape({
            json: PropTypes.object
          }),
          PropTypes.string
        ]),

        /**
         * Specifies the id of the accordion item
         */
        id: PropTypes.string,

        /**
         * Specifies the label of the accordion item
         */
        label: PropTypes.string,

        /**
         * Specifies the link label (This field is only for Topic Records)
         */
        linkLabel: PropTypes.string,

        /**
         * Specifies the link url (This field is only for Topic Records)
         */
        linkUrl: PropTypes.string
      })
    )
  }),

  /**
   * Used to open accordion item on mount
   */
  openAccordionId: PropTypes.array,

  /**
   * Specifies the theme.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions))
};

AccordionComponent.defaultProps = {
  listItems: {},
  openAccordionId: [],
  theme: 'none'
};

export default AccordionComponent;
