export default (input) => {
  return {
    ...input,
    items: input?.items?.map((el) => {
      return {
        checkboxes: (el.subTypes || el.checkboxes)?.map((el) => {
          return {
            isChecked: el.selectedByDefault || el.isChecked,
            label: el.subType || el.label,
            value: el.selectId?.toString() || el.value?.toString() || ''
          };
        }),
        icon: el.icon,
        label: el.propertyType || el.label,
        value: el.value?.toString() || el.propertyTypeId
      };
    })
  };
};
