export default (input) => {
  return {
    ...input,
    image: {
      height: input.image?.height,
      src: input.image?.src || input.image?.url,
      title: input.title,
      width: input.image?.width
    },
    link: {
      aria: input?.link?.aria || input.aria,
      label: input?.link?.label || input.linkLabel,
      url: input?.link?.url || input.link
    }
  };
};
