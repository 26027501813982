import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

const Link = (props) => {
  const router = useRouter();
  let { activeClassName, ariaLabel, children, className, url, query, forceAnchorTag } = props;

  if (!url) return null;

  // ---------------------------------------------------------

  const externalUrl = url.match(/^http/);
  const sibcyClineUrl = url.match(/^https:\/\/www.sibcycline.com/);
  const sibcyClinePathSplit = url.split('.com');

  // ---------------------------------------------------------

  const classes = classNames({
    [activeClassName]: !externalUrl && (router.pathname === url || router.asPath === url),
    [className]: className
  });

  // ---------------------------------------------------------

  if (sibcyClineUrl) {
    return (
      <NextLink className={classes} href={sibcyClinePathSplit[1]} aria-label={ariaLabel}>
        {children}
      </NextLink>
    );
  }

  if (externalUrl && !sibcyClineUrl) {
    return (
      <a href={url} className={classes} aria-label={ariaLabel} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  if (forceAnchorTag) {
    return (
      <a href={url} className={classes} aria-label={ariaLabel}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={{ pathname: url, query: query }} className={classes} aria-label={ariaLabel}>
      {children}
    </NextLink>
  );
};

Link.propTypes = {
  /**
   * Specifies text that labels the current element, when a text label is not visible on the screen.
   */
  ariaLabel: PropTypes.string,

  /**
   * Specifies what is rendered inside the link.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Used when the Link should not be using next link, for an internal URL
   */
  forceAnchorTag: PropTypes.bool,

  /**
   * Specifies any query parameters to be added to the page where the url links
   * Will be ignored on external links
   */
  query: PropTypes.object,

  /**
   * Specifies where the url links
   * (external links that start with http will open in new window).
   */
  url: PropTypes.string.isRequired
};

Link.defaultProps = {
  query: {}
};

export default Link;
