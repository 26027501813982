// ---------------------------------------------------------

import Component from './component';
import transform from './transformer';

// ---------------------------------------------------------

const ArticleCard = (props) => <Component {...transform(props)} />;

export default ArticleCard;

// ---------------------------------------------------------

export { Component as component, transform as transformer };
