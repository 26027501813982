import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import userReducer from './slices/userSlice';
import searchFiltersReducer from './slices/searchFiltersSlice';
import miscReducer from './slices/miscSlice';

const combinedReducer = combineReducers({
  miscValues: miscReducer,
  searchFilters: searchFiltersReducer,
  user: userReducer
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload
    };
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

export const makeStore = () =>
  configureStore({
    devTools: true,
    reducer
  });

export const wrapper = createWrapper(makeStore, { debug: false });
