const themeMap = {
  '50/50 Slides': 'section',
  Cards: 'cards',
  'Full-Width Slides': 'full-width',
  'Text Only': 'text-only'
};

export default (input) => {
  return {
    ...input,
    theme: themeMap[input.theme] || input.theme
  };
};
