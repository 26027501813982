import propertyTypeMap from '@src/data/property-type-map.json';

export const statusAndType = {
  filterItems: [
    {
      checkBoxes: {
        items: [
          {
            label: 'Price changes'
          },
          {
            label: 'New listings'
          },
          {
            label: 'Open houses'
          },
          {
            label: 'New construction'
          },
          {
            label: 'Featured'
          }
        ],
        layout: 'half'
      },
      id: 'propertyStatus',
      title: 'Property status'
    },
    {
      checkBoxes: {
        items: [
          {
            label: 'Active'
          },
          {
            label: 'Pending'
          },
          {
            label: 'Sold'
          }
        ],
        layout: 'half',
        shouldAllCheckedUnselect: true
      },
      id: 'saleStatus',
      title: 'Sale status'
    },
    {
      id: 'propertyType',
      radioButtons: {
        additionalOptions: true,
        clearLabel: 'Any',
        clearValue: [],
        items: propertyTypeMap.slice(1)
      },
      title: 'Property type'
    }
  ],
  label: 'Status & Type'
};

// ---------------------------------------------------------

export const bedAndBath = {
  filterItems: [
    {
      id: 'bedrooms',
      radioButtons: {
        clearLabel: 'All',
        clearValue: 0,
        items: [
          {
            label: '1+',
            value: 1
          },
          {
            label: '2+',
            value: 2
          },
          {
            label: '3+',
            value: 3
          },
          {
            label: '4+',
            value: 4
          },
          {
            label: '5+',
            value: 5
          }
        ],
        theme: 'with-label-inside'
      },
      title: 'Bedrooms'
    },
    {
      id: 'bathrooms',
      radioButtons: {
        clearLabel: 'All',
        clearValue: 0,
        items: [
          {
            label: '1+',
            value: 1
          },
          {
            label: '2+',
            value: 2
          },
          {
            label: '3+',
            value: 3
          },
          {
            label: '4+',
            value: 4
          },
          {
            label: '5+',
            value: 5
          }
        ],
        theme: 'with-label-inside'
      },
      title: 'Bathrooms'
    }
  ],
  label: 'Bed & Bath'
};

// ---------------------------------------------------------
export const daysSinceOptions = [
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
  { label: '6 months', value: 180 },
  { label: '1 year', value: 365 },
  { label: '2 years', value: 730 },
  { label: '3 years', value: 1095 }
];

export const priceOptions = [
  { label: '$10,000', value: 10000 },
  { label: '$15,000', value: 15000 },
  { label: '$20,000', value: 20000 },
  { label: '$25,000', value: 25000 },
  { label: '$30,000', value: 30000 },
  { label: '$35,000', value: 35000 },
  { label: '$40,000', value: 40000 },
  { label: '$45,000', value: 45000 },
  { label: '$50,000', value: 50000 },
  { label: '$55,000', value: 55000 },
  { label: '$60,000', value: 60000 },
  { label: '$65,000', value: 65000 },
  { label: '$70,000', value: 70000 },
  { label: '$75,000', value: 75000 },
  { label: '$80,000', value: 80000 },
  { label: '$85,000', value: 85000 },
  { label: '$90,000', value: 90000 },
  { label: '$95,000', value: 95000 },
  { label: '$100,000', value: 100000 },
  { label: '$125,000', value: 125000 },
  { label: '$150,000', value: 150000 },
  { label: '$175,000', value: 175000 },
  { label: '$200,000', value: 200000 },
  { label: '$225,000', value: 225000 },
  { label: '$250,000', value: 250000 },
  { label: '$275,000', value: 275000 },
  { label: '$300,000', value: 300000 },
  { label: '$325,000', value: 325000 },
  { label: '$350,000', value: 350000 },
  { label: '$375,000', value: 375000 },
  { label: '$400,000', value: 400000 },
  { label: '$425,000', value: 425000 },
  { label: '$450,000', value: 450000 },
  { label: '$475,000', value: 475000 },
  { label: '$500,000', value: 500000 },
  { label: '$525,000', value: 525000 },
  { label: '$550,000', value: 550000 },
  { label: '$575,000', value: 575000 },
  { label: '$600,000', value: 600000 },
  { label: '$625,000', value: 625000 },
  { label: '$650,000', value: 650000 },
  { label: '$675,000', value: 675000 },
  { label: '$700,000', value: 700000 },
  { label: '$725,000', value: 725000 },
  { label: '$750,000', value: 750000 },
  { label: '$775,000', value: 775000 },
  { label: '$800,000', value: 800000 },
  { label: '$850,000', value: 850000 },
  { label: '$900,000', value: 900000 },
  { label: '$950,000', value: 950000 },
  { label: '$1,000,000', value: 1000000 },
  { label: '$1,250,000', value: 1250000 },
  { label: '$1,500,000', value: 1500000 },
  { label: '$1,750,000', value: 1750000 },
  { label: '$2,000,000', value: 2000000 },
  { label: '$2,250,000', value: 2250000 },
  { label: '$2,500,000', value: 2500000 },
  { label: '$3,000,000', value: 3000000 },
  { label: '$3,500,000', value: 3500000 },
  { label: '$4,000,000', value: 4000000 },
  { label: '$5,000,000', value: 5000000 },
  { label: '$6,000,000', value: 6000000 },
  { label: '$7,000,000', value: 7000000 },
  { label: '$8,000,000', value: 8000000 },
  { label: '$9,000,000', value: 9000000 },
  { label: '$10,000,000', value: 10000000 }
];

export const priceMultipleSelects = [
  {
    label: 'Minimum',
    options: priceOptions
  },
  {
    label: 'Maximum',
    options: priceOptions
  }
];

export const price = {
  filterItems: [
    {
      id: 'price',
      multipleSelect: {
        anyLabel: 'Any Price',
        max: 10000000,
        min: 0,
        options: priceOptions
      },
      title: 'Price'
    }
  ],
  label: 'Price'
};

// ---------------------------------------------------------

export const additional = {
  filterItems: [
    {
      id: 'squareFeet',
      rangeSlider: {
        anyLabel: 'Any Size',
        interval: 200,
        marksSuffix: ' ft',
        max: 8000,
        min: 0
      },
      title: 'Square Feet'
    },
    {
      id: 'garageSpaces',
      radioButtons: {
        clearLabel: 'All',
        clearValue: 0,
        items: [
          {
            label: '1+',
            value: 1
          },
          {
            label: '2+',
            value: 2
          },
          {
            label: '3+',
            value: 3
          },
          {
            label: '4+',
            value: 4
          }
        ],
        theme: 'with-label-inside'
      },
      title: 'Garage Spaces'
    },
    {
      checkBoxes: {
        items: [
          {
            label: 'Finished'
          },
          {
            label: 'Partially Finished'
          },
          {
            label: 'Unfinished'
          }
        ],
        layout: 'half',
        shouldAllCheckedUnselect: false
      },
      id: 'basement',
      title: 'Basement'
    },
    {
      checkBoxes: {
        items: [
          {
            label: 'Gas'
          },
          {
            label: 'Electric'
          },
          {
            label: 'Steam'
          },
          {
            label: 'Forced Air'
          },
          {
            label: 'Heat pump'
          },
          {
            label: 'Gravity'
          },
          {
            label: 'Geothermal'
          }
        ],
        layout: 'half',
        shouldAllCheckedUnselect: false
      },
      id: 'heatingType',
      title: 'Heating type'
    },
    {
      id: 'lotSize',
      rangeSlider: {
        anyLabel: 'Any size',
        interval: 25,
        marksSuffix: ' ac',
        max: 500,
        min: 0
      },
      title: 'Lot Size'
    },
    {
      checkBoxes: {
        items: [
          {
            label: '1st floor bedroom'
          },
          {
            label: 'Formal dining'
          },
          {
            label: '1st floor bathroom'
          },
          {
            label: 'Living room'
          },
          {
            label: 'Swimming'
          },
          {
            label: 'Central air'
          }
        ],
        layout: 'half',
        selectAllLabel: null,
        shouldAllCheckedUnselect: false
      },
      id: 'mustHaves',
      title: 'Must Haves'
    }
  ],
  label: 'Additional Filters'
};

// ---------------------------------------------------------

export default {
  additional: additional,
  bedAndBath: bedAndBath,
  price: price,
  statusAndType: statusAndType
};
