export const relocationNav = {
  "header": {
    "main_navigation": [
      {
        "url": "/relocation/corporate-moves",
        "label": "Corporate Relocations"
      },
      {
        "url": "/relocation/meet-the-team",
        "label": "Meet the Team"
      },
      {
        "url": "/relocation/personal-moves",
        "label": "Find an Agent"
      },
      {
        "url": "/relocation/resources",
        "label": "Resources"
      },
      {
        "url": "tel:1-800-321-3403",
        "label": "800-321-3403"
      }
    ],
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/6fqWkltm0EZJxGIE9ozRty/3083649d6fa931c937c82cd1103d1255/relocation-color-logo.png",
      "width": 480,
      "height": 106
    }
  },
  "footer": {
    "menuLinks": [
      {
        "url": "/relocation/corporate-move",
        "label": "Corporate Move"
      },
      {
        "url": "/relocation/meet-the-team",
        "label": "Meet the Team"
      },
      {
        "url": "/relocation/personal-moves",
        "label": "Find An Agent"
      }
    ],
    "legalLinks": [
      {
        "url": "/about/privacy",
        "label": "Privacy"
      },
      {
        "url": "/about/accessibility",
        "label": "Accessibility"
      },
      {
        "url": "/about/terms-of-use",
        "label": "Notices"
      }
    ],
    "rightImage": {
      "url": "https://www.leadingre.com/?coid=7884",
      "image": {
        "src": "/images/logos/logo-lrecotw-white@3x.png",
        "width": "77px",
        "height": "60px"
      }
    },
    "copyrightText": "Sibcy Cline Relocation Services, Inc.",
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/QJZfLLGPLB1iFfc8CUP5H/3492e6d41a02940c4d6aa606bac1f7ec/relocation-white-logo.png",
      "width": 480,
      "height": 106
    }
  }
};
export const mainNav = {
  "header": {
    "my_account": {
      "label": "My account",
      "subLinks": [
        {
          "children": [
            {
              "url": "/account/liked",
              "label": "Liked"
            },
            {
              "url": "/account/searches",
              "label": "Searches"
            },
            {
              "url": "/account/notes",
              "label": "Notes"
            },
            {
              "url": "/account/notifications",
              "label": "Notifications"
            },
            {
              "url": "/account",
              "label": "Settings"
            },
            {
              "url": "/api/auth/logout",
              "label": "Logout"
            }
          ]
        }
      ]
    },
    "main_navigation": [
      {
        "label": "Buy",
        "subLinks": [
          {
            "heading": "Browse",
            "children": [
              {
                "url": "/browse?search_type=all_listings",
                "label": "Browse A-Z",
                "forceAnchorTag": true
              },
              {
                "url": "/coming-soon",
                "label": "Coming Soon"
              },
              {
                "url": "/browse",
                "label": "New Listings",
                "forceAnchorTag": true
              },
              {
                "url": "/browse?search_type=price_changes",
                "label": "Price Changes",
                "forceAnchorTag": true
              },
              {
                "url": "/browse?search_type=open_houses",
                "label": "Open Houses",
                "forceAnchorTag": true
              },
              {
                "url": "/browse?search_type=new_construction",
                "label": "New Construction",
                "forceAnchorTag": true
              },
              {
                "url": "/builder",
                "label": "Premier Builders"
              },
              {
                "url": "https://www.leadingre.com/?coid=7884",
                "label": "National Search"
              },
              {
                "url": "/results?status=Active&status=Pending",
                "label": "View All Properties",
                "forceAnchorTag": true
              }
            ]
          },
          {
            "heading": "Premier Collection",
            "children": [
              {
                "url": "/premier-collection",
                "label": "Luxury Homes"
              },
              {
                "url": "/builder",
                "label": "Find a Builder"
              },
              {
                "url": "/development",
                "label": "Explore Developments"
              }
            ]
          },
          {
            "heading": "Get Help",
            "children": [
              {
                "url": "https://mortgage.sibcycline.com/Jumpstart",
                "label": "Get Pre-Approved"
              },
              {
                "url": "/guidance/buyer-guide",
                "label": "Buyer’s Guide"
              },
              {
                "url": "/find-an-agent",
                "label": "Find an Agent"
              },
              {
                "url": "https://mortgage.sibcycline.com/FindLoanOfficer",
                "label": "Find a Loan Officer"
              }
            ]
          }
        ]
      },
      {
        "label": "Sell",
        "subLinks": [
          {
            "children": [
              {
                "url": "/sell-your-home",
                "label": "Sell your Home"
              },
              {
                "url": "/guidance/seller-guide",
                "label": "Seller’s Guide"
              },
              {
                "url": "/find-an-agent",
                "label": "Find an Agent"
              },
              {
                "url": "https://homeservices.sibcycline.com/",
                "label": "Home Services"
              }
            ]
          }
        ]
      },
      {
        "label": "Guidance",
        "subLinks": [
          {
            "children": [
              {
                "url": "/schools",
                "label": "School Links"
              },
              {
                "url": "/community",
                "label": "Community Guide"
              },
              {
                "url": "/guidance/buyer-guide",
                "label": "Buyer’s Guide"
              },
              {
                "url": "/guidance/seller-guide",
                "label": "Seller’s Guide"
              },
              {
                "url": "/guidance/regions",
                "label": "Regional Guide"
              },
              {
                "url": "/sell-your-home",
                "label": "What's Your Home Worth?"
              },
              {
                "url": "/guidance/utility-connection",
                "label": "Utility Connection"
              },
              {
                "url": "/blog",
                "label": "Blog"
              }
            ]
          }
        ]
      },
      {
        "label": "Services",
        "subLinks": [
          {
            "children": [
              {
                "url": "/services",
                "label": "Overview"
              },
              {
                "url": "https://mortgage.sibcycline.com/",
                "label": "Mortgage"
              },
              {
                "url": "https://insurance.sibcycline.com/",
                "label": "Insurance"
              },
              {
                "url": "https://title.sibcycline.com/",
                "label": "Title"
              },
              {
                "url": "https://relocation.sibcycline.com/",
                "label": "Relocation"
              },
              {
                "url": "https://relocation.sibcycline.com/military-on-the-move",
                "label": "U.S. Military on the Move"
              },
              {
                "url": "https://homeservices.sibcycline.com/",
                "label": "Home Services"
              },
              {
                "url": "/programs/home-warranty",
                "label": "Home Warranty"
              },
              {
                "url": "/earnest",
                "label": "Earnest Money Payment"
              }
            ]
          }
        ]
      },
      {
        "label": "Agents",
        "subLinks": [
          {
            "children": [
              {
                "url": "/offices",
                "label": "Offices"
              },
              {
                "url": "/find-an-agent",
                "label": "Find an Agent"
              },
              {
                "url": "https://mortgage.sibcycline.com/FindLoanOfficer",
                "label": "Find a Loan Officer"
              },
              {
                "url": "https://joinsibcycline.com/",
                "label": "Becoming an Agent"
              }
            ]
          }
        ]
      }
    ],
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/6zn4KuBh31VDdj5UgAoeOs/964edb516df6e5d6ce3d5f1c9137c036/icons-logos-sibcy-horizontal-white.svg",
      "width": 230,
      "height": 51
    }
  },
  "footer": {
    "menuLinks": [
      {
        "url": "/about",
        "label": "About us"
      },
      {
        "url": "/careers",
        "label": "Careers"
      },
      {
        "url": "/find-an-agent",
        "label": "Find an agent"
      },
      {
        "url": "/contact",
        "label": "Contact us"
      }
    ],
    "legalLinks": [
      {
        "url": "/about/privacy",
        "label": "Privacy"
      },
      {
        "url": "/about/accessibility",
        "label": "Accessibility"
      },
      {
        "url": "/about/terms-of-use",
        "label": "Notices"
      }
    ],
    "rightImage": {
      "url": "https://www.leadingre.com/?coid=7884",
      "image": {
        "src": "/images/logos/logo-lrecotw-white@3x.png",
        "width": "77px",
        "height": "60px"
      }
    },
    "copyrightText": "Sibcy Cline, Inc. All rights reserved. All Information is believed accurate, but is NOT guaranteed.",
    "description": {
      "json": {
        "data": {},
        "content": [
          {
            "data": {},
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "Sibcy Cline proudly offers real estate services in Cincinnati Ohio, Northern Kentucky, Dayton Ohio and Southeast Indiana. We're one of the largest local, independent, and family-owned brokerages in the region.",
                "nodeType": "text"
              }
            ],
            "nodeType": "paragraph"
          }
        ],
        "nodeType": "document"
      }
    },
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/6zn4KuBh31VDdj5UgAoeOs/964edb516df6e5d6ce3d5f1c9137c036/icons-logos-sibcy-horizontal-white.svg",
      "width": 230,
      "height": 51
    }
  }
};
export const homeServicesNav = {
  "footer": {
    "menuLinks": [
      {
        "url": "https://online.sibcycline.com/informationrequest/default.aspx?service=homeservices&rep=homeservices&sender=OrderServices",
        "label": "Order Services"
      },
      {
        "url": "tel:5139859565",
        "label": "513-985-9565"
      },
      {
        "url": "tel:8889699944",
        "label": "888-969-9944"
      }
    ],
    "legalLinks": [
      {
        "url": "/about/privacy",
        "label": "Privacy"
      },
      {
        "url": "/about/accessibility",
        "label": "Accessibility"
      },
      {
        "url": "/about/terms-of-use",
        "label": "Notices"
      }
    ],
    "rightImage": {
      "url": "https://www.leadingre.com/?coid=7884",
      "image": {
        "src": "/images/logos/logo-lrecotw-white@3x.png",
        "width": "77px",
        "height": "60px"
      }
    },
    "copyrightText": "Sibcy Cline, Inc. All rights reserved. All Information is believed accurate, but is NOT guaranteed. Serving Greater Cincinnati, Greater Dayton, Northern Kentucky and Southeast Indiana",
    "description": {
      "json": {
        "data": {},
        "content": [
          {
            "data": {},
            "content": [
              {
                "data": {},
                "marks": [],
                "value": "We know how challenging it can be to find dependable professionals when you have maintenance, repairs or remodeling needs. Sibcy Cline Home Services is there to help by connecting you to reliable services and vendors.",
                "nodeType": "text"
              }
            ],
            "nodeType": "paragraph"
          }
        ],
        "nodeType": "document"
      }
    },
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/4QFhBOTJHxV6DTyTWMh4SP/0f4884449e4f73eb53219a862f084efe/homeservices-white-logo.png",
      "width": 200,
      "height": 44
    }
  },
  "header": {
    "main_navigation": [
      {
        "url": "https://online.sibcycline.com/informationrequest/default.aspx?service=homeservices&rep=homeservices&sender=OrderServices",
        "label": "Order Services"
      },
      {
        "url": "tel:5139859565",
        "label": "513-985-9565"
      },
      {
        "url": "tel:8889699944",
        "label": "888-969-9944"
      }
    ],
    "logo": {
      "url": "https://images.ctfassets.net/wzzep3ntdgx4/3cYb8dquI3sf0YZUKtJjiR/7c8a8f3763629cd5b44eeb6d3968ce30/homeservices-color-logo.png",
      "width": 480,
      "height": 106
    }
  }
};
