// ---------------------------------------------------------

import Component from './component';
import iconOptions from './icons';

// ---------------------------------------------------------

const Icon = (props) => <Component {...props} />;

export default Icon;

// ---------------------------------------------------------

export { Component as component, iconOptions };
