export default {
  props: {
    links: [
      { icon: 'x', url: '#' },
      { icon: 'facebook', url: '#' },
      { icon: 'instagram', url: '#' },
      { icon: 'linkedin', url: '#' }
    ]
  }
};
