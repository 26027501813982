const transformed = (input) => {
  // TODO remove some of these when field labels have been standardized

  return {
    ...input,
    excerpt: input.excerpt || input.body,
    image: input.image || input.featuredImage,
    linkLabel: input.linkLabel || input.label,
    linkUrl: input.linkUrl || input.slug,
    parent: input.parentPage || input.parentTopic || input.category,
    title: input.title || input.builderDevelopmentName
  };
};

export default transformed;
